import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "text-muted",
  style: {"font-weight":"bold"}
}
const _hoisted_2 = { class: "text-muted" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "text-red"
}
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "text-red"
}
const _hoisted_9 = { class: "text-red" }
const _hoisted_10 = {
  key: 2,
  class: "text-muted bold f12"
}
const _hoisted_11 = { class: "text-muted" }
const _hoisted_12 = { class: "text-muted" }
const _hoisted_13 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeLoadingIndicator = _resolveComponent("SeLoadingIndicator")!
  const _component_SeImage = _resolveComponent("SeImage")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createVNode(_component_SeImage, {
        maxWidth: "40",
        maxHeight: "40",
        src: _ctx.result.element.getIcon()
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SeLoadingIndicator, { size: 40 })
        ]),
        _: 1
      }, 8, ["src"])
    ]),
    _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.result.element.getName()), 1),
    _createElementVNode("td", _hoisted_2, [
      (_ctx.result.isTemperatureRangeValid())
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.result.temperature != null)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.result.temperature) + "°C ", 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_5, "Non prise")),
            (_ctx.result.firstTemperature != null)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, "(" + _toDisplayString(_ctx.result.firstTemperature) + "°C)", 1))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_ctx.result.temperature != null)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.result.temperature) + "°C ", 1))
              : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_9, " (" + _toDisplayString(_ctx.result.element.getMinimumTemperature()) + "°C | " + _toDisplayString(_ctx.result.element.getMaximumTemperature()) + "°C)", 1)
          ])),
      (_ctx.result.control_temperature != null)
        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.result.control_temperature) + "°C (contrôle) ", 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.result.comment), 1),
    _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.DateUtils.formatDateFromSeconds(_ctx.result.timestamp.seconds)), 1),
    _createElementVNode("td", _hoisted_13, [
      (_ctx.result.pictures.length > 0)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.displayPictures())),
            class: "image-button",
            src: "/ico/eye-blue.svg"
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}