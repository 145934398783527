import { DocumentReference, Timestamp } from "firebase/firestore";
import TaskResult from "./TaskResult";
import Element from "@/model/Elements/Element";
import Employee from "../Employees/Employee";

export default class TemperatureControlResult extends TaskResult {
  element: Element | null;
  target_temperature: number | null;
  start_temperature: number | null;
  start_timestamp: Timestamp | null;
  start_employee: Employee | null;
  end_temperature: number | null;
  end_timestamp: Timestamp | null;
  end_employee: Employee | null;
  comment: string | null;
  mode: string | null;

  constructor(
    element: Element | null,
    target_temperature: number | null,
    start_temperature: number | null,
    start_timestamp: Timestamp | null,
    start_employee: Employee | null,
    end_temperature: number | null,
    end_timestamp: Timestamp | null,
    end_employee: Employee | null,
    comment: string | null,
    mode: string | null
  ) {
    super(null, null, null);
    this.element = element;
    this.target_temperature = target_temperature;
    this.start_temperature = start_temperature;
    this.start_timestamp = start_timestamp;
    this.start_employee = start_employee;
    this.end_temperature = end_temperature;
    this.end_timestamp = end_timestamp;
    this.end_employee = end_employee;
    this.comment = comment;
    this.mode = mode;
  }
}
