import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex col grow",
  style: {"width":"450px","gap":"70px"}
}
const _hoisted_2 = { class: "flex justify-between center" }
const _hoisted_3 = {
  class: "flex col grow",
  style: {"width":"450px","gap":"70px"}
}
const _hoisted_4 = { class: "flex justify-between center" }
const _hoisted_5 = { class: "main-layout" }
const _hoisted_6 = {
  class: "flex col g24",
  style: {"margin-bottom":"24px"}
}
const _hoisted_7 = { class: "flex justify-between" }
const _hoisted_8 = {
  class: "flex justify-end",
  style: {"width":"68%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_SeModal = _resolveComponent("SeModal")!
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_SeInput = _resolveComponent("SeInput")!
  const _component_ToggleSwitch = _resolveComponent("ToggleSwitch")!
  const _component_AuditingModuleTab = _resolveComponent("AuditingModuleTab")!
  const _component_SeForm = _resolveComponent("SeForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SeModal, {
      title: _ctx.Lang.getI18N('warning-dialog'),
      ref: "warningModal"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("audit-published-warning")), 1),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_SeButton, {
              onPress: () => _ctx.cancelAuditEdit(_ctx.$refs.warningModal),
              style: {"width":"100px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("cancel")), 1)
              ]),
              _: 1
            }, 8, ["onPress"]),
            _createVNode(_component_SeButton, {
              style: {"width":"100px"},
              onPress: () => _ctx.closeModal(_ctx.$refs.warningModal)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("edit")), 1)
              ]),
              _: 1
            }, 8, ["onPress"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_SeModal, {
      title: _ctx.Lang.getI18N('warning-dialog'),
      ref: "publishConfirmationModal"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("audit-publish-confirmation")), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_SeButton, {
              onPress: () => _ctx.cancelTogglePublish(_ctx.$refs.publishConfirmationModal),
              style: {"width":"100px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("cancel")), 1)
              ]),
              _: 1
            }, 8, ["onPress"]),
            _createVNode(_component_SeButton, {
              style: {"width":"100px"},
              onPress: () => _ctx.closeModal(_ctx.$refs.publishConfirmationModal)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("publish")), 1)
              ]),
              _: 1
            }, 8, ["onPress"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_NavHeader, {
        backButton: "true",
        title: "Auditing",
        displayWarning: true
      }),
      _createVNode(_component_SeForm, {
        ref: "submitForm",
        class: "container",
        style: {"height":"100%"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_SeInput, {
                placeholder: _ctx.Lang.getI18N('name'),
                ref: "name",
                modelValue: _ctx.state.audit!.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.audit!.name) = $event)),
                required: "",
                style: {"width":"30%"}
              }, null, 8, ["placeholder", "modelValue"]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_ToggleSwitch, {
                  modelValue: _ctx.state.audit!.active,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.audit!.active) = $event))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("active")), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_ToggleSwitch, {
                  auto: false,
                  modelValue: _ctx.state.audit!.self_evaluation,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.audit!.self_evaluation) = $event))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("self-evaluation")), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_ToggleSwitch, {
                  ref: "published",
                  auto: false,
                  modelValue: _ctx.state.audit!.published,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.audit!.published) = $event))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("published")), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_SeButton, {
                  tooltip: _ctx.Lang.getI18N('duplicate'),
                  onPress: _ctx.duplicateAudit,
                  ref: "duplicateBtn",
                  icon: "/ico/copy.svg"
                }, null, 8, ["tooltip", "onPress"]),
                _createVNode(_component_SeButton, {
                  tooltip: _ctx.Lang.getI18N('save'),
                  onPress: _ctx.saveAudit,
                  ref: "saveBtn",
                  icon: "/ico/save.svg"
                }, null, 8, ["tooltip", "onPress"])
              ])
            ])
          ]),
          _createVNode(_component_AuditingModuleTab, {
            ref: "moduleTab",
            audit: _ctx.state.audit!,
            onUpdateAudit: _ctx.handleUpdateAudit
          }, null, 8, ["audit", "onUpdateAudit"])
        ]),
        _: 1
      }, 512)
    ])
  ], 64))
}