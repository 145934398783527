
import * as Lang from "@/i18n/lang";
import { getState as getAppState } from "@/pinia/AppState";
import { defineComponent } from "vue";
import Distributor from "@/model/Distributor";
import * as Storage from "@/firebase/Storage";
import * as Snackbars from "@/utils/Snackbars";
import { get } from "lodash";
import { getShopState } from "@/pinia/shop/ShopState";
import DistributorShop from "@/components/shop/DistributorShop.vue";
import * as UploadManager from "@/model/Uploads/UploadManager";
import ToggleSwitch from "@/components/global/ToggleSwitch.vue";

export default defineComponent({
  async setup() {
    var state = getShopState();

    await state.setup();

    return {
      state: state,
    };
  },
  data() {
    return {
      Lang: Lang,
    };
  },
  mounted() { },
  methods: {
    async save() {
      let refs = <any>this.$refs;

      await UploadManager.applyUploads();

      let index = 0;

      if (this.state.distributor.shop_email == undefined) {
        this.state.distributor.shop_email = null;
      }
      await this.state.distributor.set();

      for (let shopItem of this.state.shopItems) {
        if (shopItem.illustration_path != null) {
          shopItem.illustration = await Storage.getDownloadUrl(
            shopItem.illustration_path
          );
        }
        shopItem.index = index++;
        await shopItem.set();
      }


      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
  },
  components: { DistributorShop },
});
