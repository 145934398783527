import {
  collection,
  query,
  where,
  getDocs,
  DocumentData,
  Query,
  or,
  DocumentReference,
  and,
} from "firebase/firestore";

import User, { Role } from "../model/User";
import { defineStore } from "pinia";
import { SnapshotListener } from "@/utils/SnapshotListener";
import Training from "@/model/Trainings/Training";
import TrainingResult from "@/model/Trainings/TrainingResult";
import * as Firebase from "@/firebase/Firebase";
import Database from "@/model/Database";
import Store from "@/model/Store";

interface TrainingManager {}

export const getTrainingManager = defineStore("TrainingManager", {
  state: (): TrainingManager => {
    return {};
  },
  actions: {
    async getActiveTrainings(user: User, store: Store): Promise<Training[]> {
      if (store.database === null) {
        return;
      }

      var results = [] as Training[];
      var trainings: Training[] = await this.getTrainings(user, store.database);

      for (let training of trainings) {
        if (
          training.target.stores.length == 0 &&
          training.target.store_tags.length == 0
        ) {
          results.push(training);
          continue;
        }

        if (
          training.target.stores.length > 0 &&
          training.target.store_tags.length == 0 &&
          !training.target.stores.includes(store.ref)
        ) {
          continue;
        }

        if (training.target.store_tags_union) {
          if (store.tags.some((x) => training.target.store_tags.includes(x))) {
            results.push(training);
            continue;
          }
        } else {
          if (store.tags.some((x) => !training.target.store_tags.includes(x))) {
            continue;
          }

          results.push(training);
        }
      }

      return results;
    },

    async getTrainings(
      user: User,
      database: DocumentReference | null = null
    ): Promise<Training[]> {
      let q: Query<DocumentData> | null = null;

      if (user.role == Role.Manager) {
        q = query(
          collection(Firebase.firestore, "trainings"),
          where("source", "==", database)
        );
      } else if (user.role == Role.Admin) {
        q = query(
          collection(Firebase.firestore, "trainings"),
          where("type", "==", "secureat")
        );
      } else if (user.role == Role.Distributor) {
        if (database == null) {
          q = query(
            collection(Firebase.firestore, "trainings"),
            where("source", "==", user.distributor)
          );
        } else {
          q = query(
            collection(Firebase.firestore, "trainings"),
            or(
              where("source", "==", database),
              where("source", "==", user.distributor)
            )
          );
        }
      } else {
        q = query(
          collection(Firebase.firestore, "trainings"),
          where("source", "==", database)
        );
      }

      let snapshot = await getDocs(q);

      return snapshot.docs.map((x) => Training.fromFirestore(x));
    },

    async fetchTrainingResults(training: Training, currentStore: Store | null) {
      let path = training.ref.path + "/results";

      if (currentStore != null) {
        var q = query(
          collection(Firebase.firestore, path),
          and(
            where("store", "==", currentStore.ref),
            where("completed_timestamp", "!=", null)
          )
        );
      } else {
        var q = query(
          collection(Firebase.firestore, path),
          where("completed_timestamp", "!=", null)
        );
      }

      let snapshot = await getDocs(q);

      return snapshot.docs.map((x) => TrainingResult.fromFirestore(x));
    },

    async getTrainingResusts(training: Training) {
      let path = training.ref.path + "/results";

      const q = query(
        collection(Firebase.firestore, path),
        where("completed_timestamp", "!=", null)
      );

      return new SnapshotListener<TrainingResult>(
        TrainingResult.fromFirestore,
        q
      );
    },
  },
});
