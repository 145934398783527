
import { collection, doc, getDocs, query, updateDoc } from "firebase/firestore";
import NavPanel from "../nav/NavPanel.vue";
import NavHeader from "../nav/NavHeader.vue";
import SearchBar from "../components/global/SearchBar.vue";
import ToggleSwitch from "../components/global/ToggleSwitch.vue";
import SeInput from "../components/global/SeInput.vue";
import { getDistributorManager } from "../firebase/DistributorManager";
import User, { Role } from "@/model/User";
import { getState } from "@/pinia/NavigationState";
import { getState as getAppState } from "@/pinia/AppState";
import Distributor from "@/model/Distributor";
import AccountList from "@/components/accounts/AccountList.vue";
import WideCard from "@/components/global/WideCard.vue";
import { defineComponent, nextTick } from "vue";
import * as Lang from "@/i18n/lang";
import SeInputFile from "@/components/global/SeInputFile.vue";
import * as Storage from "@/firebase/Storage";
import SeCountryDropdown from "@/components/global/SeCountryDropdown.vue";
import * as Snackbars from "@/utils/Snackbars";
import { getAccountState } from "@/pinia/accounts/AccountState";
import { getDistributorState } from "@/pinia/distributors/DistributorState";
import { getUserManager } from "@/firebase/UserManager";
import * as UploadManager from "@/model/Uploads/UploadManager";
import ReadonlyField from "@/components/global/ReadonlyField.vue";
import * as Crypto from "@/utils/Crypto";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import { getStoreManager } from "@/firebase/StoreManager";
import * as Firebase from "@/firebase/Firebase";
import Store from "@/model/Store";
import StoreElement from "@/model/Elements/StoreElement";

export default defineComponent({
  components: {
    AccountList,
    SeInputFile,
    SeCountryDropdown,
  },
  mounted() {
    var refs = <any>this.$refs;

    nextTick(() => {
      if (this.state.distributor!.countries.length > 0) {
        refs.countrySelect.selectCountryCode(
          this.state.distributor!.countries[0]
        );
      }
    });
  },
  data() {
    return {
      activeStores: [] as Store[],

      probesCount: 0,
    };
  },
  async setup() {
    var state = getDistributorState();

    let contacts: User[] = await getUserManager().getDistributorContacts(
      state.distributor!
    );

    return {
      Lang: Lang,
      Role: Role,
      role: getAppState().userData.role,

      state: state,
      contacts: contacts,
    };
  },
  methods: {
    async openDetailModal() {
      var refs = <any>this.$refs;


      this.activeStores = await getStoreManager()
        .getStores(true)
        .filter(
          (x) =>
            x.distributor.id == this.state.distributor.ref.id &&
            x.active &&
            x.secureat_licence
        );

      this.probesCount = 0;

      // Créer un tableau pour stocker toutes les promesses
      let promises = this.activeStores.map(async (store) => {
        let q = query(
          collection(
            Firebase.firestore,
            "stores/" + store.ref.id + "/elements/"
          )
        );

        const elementsSnapshot = await getDocs(
          collection(
            Firebase.firestore,
            "stores/" + store.ref?.id + "/elements"
          )
        );

        if (store.koovea_sticker != null) {
          let results: StoreElement[] = elementsSnapshot.docs.map((x) =>
            StoreElement.fromFirestore(x)
          );

          for (let result of results) {
            if (result.probe_id != null) {
              this.probesCount++;
            }
          }
        }
      });

      // Attendre que toutes les promesses soient terminées
      await Promise.all(promises);

      refs.detailModal.open();
    },
    openKooveaModal() {
      var refs = <any>this.$refs;
      refs.kooveaModal.open();
    },
    async closeKooveaModal() {
      var refs = <any>this.$refs;


      let password = await Crypto.hashSHA256(this.state.kooveaPasswordClear);
      let result = await SecureatServerApi.validKooveaCredentials(
        this.state.distributor.koovea_email,
        password
      );


      if (result.status == "false") {
        Snackbars.display("Identifiants Koovea incorrects.", 5000, "error");
      } else {
        Snackbars.display("Connexion avec Koovea établie.", 5000, "info");

        if (
          this.state.kooveaPasswordClear != null &&
          this.state.kooveaPasswordClear.trim() != ""
        ) {
          this.state.distributor.koovea_password = password;

          await updateDoc(this.state.distributor.ref, {
            koovea_email: this.state.distributor.koovea_email,
            koovea_password: password,
          });
        }

        refs.kooveaModal.close();
      }
    },
    async pickIllustration(event: ProgressEvent, file: File) {
      await Storage.uploadDistributorLogo(this.state.distributor!, file);

      this.state.distributor!.logo = await Storage.getDistributorLogoUrl(
        this.state.distributor!
      );

      await this.state.distributor?.set();
    },
    openAccount(account: User) {
      getAccountState().selectedAccount = account;
      getState().navigate("account");
    },

    async updateDistributor() {
      let refs = <any>this.$refs;
      this.state.distributor!.countries = [] as string[];

      var country = refs.countrySelect.getCountry();

      if (country != null) {
        this.state.distributor!.countries.push(country.code);
      }

      await UploadManager.applyUploads();

      await this.state.distributor!.set();


      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
  },
});
