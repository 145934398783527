import {
  DocumentSnapshot,
  DocumentReference,
  doc,
  Firestore,
  collection,
} from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import Element from "./Element";
import DatabaseElement from "./DatabaseElement";
import * as ImageKit from "@/utils/ImageUtils";
import { AllergenEnum } from "../AllergensEnum";
import Attachment from "../Filesystem/Attachment";
import * as Firebase from "@/firebase/Firebase";
import Store from "../Store";

export default class StoreElement extends Element {
  public isRemote = true;

  public originValue: DatabaseElement | null = null;
  public origin: DocumentReference | null;

  public active: boolean | null;
  public illustration_path: string | null;
  public illustration_url: string | null;
  public labels_module_active: boolean | null;
  public maximum_temperature: number | null;
  public minimum_temperature: number | null;
  public name: string | null;
  public temperature_picture: boolean | null;
  public temperature_delivery_item: boolean | null;
  public temperatures_module_active: boolean | null;
  public traceability_module_active: boolean | null;
  public type: DocumentReference | null;
  public oils_module_active: boolean | null;
  public maximum_polarity: number | null;
  public temperature_control_module_active: boolean | null;
  public reheating_temperature: number | null;
  public cooling_temperature: number | null;
  public allergens: AllergenEnum[] | null;
  public attachments: Attachment[] | null;
  public probe_id: string | null;
  public oils_require_picture: boolean | null;
  public reheating_duration: number | null;
  public cooling_duration: number | null;
  public temperature_require_picture: boolean | null;
  public encyclopedia_extension_active: boolean | null = null;
  constructor(
    ref: DocumentReference,

    active: boolean | null = false,
    illustration_path: string | null = null,
    illustration_url: string | null = null,
    labels_module_active: boolean | null = null,
    maximum_temperature: number | null = null,
    minimum_temperature: number | null = null,
    name: string | null = null,
    temperature_picture: boolean | null = null,
    temperature_delivery_item: boolean | null = null,
    temperatures_module_active: boolean | null = null,
    traceability_module_active: boolean | null = null,
    type: DocumentReference | null = null,
    oils_module_active: boolean | null = null,
    maximum_polarity: number | null = null,
    reheating_temperature: number | null,
    temperature_control_module_active: boolean | null = null,
    cooling_temperature: number | null,
    allergens: AllergenEnum[] | null,
    attachments: Attachment[] | null,
    probe_id: string | null,
    oils_require_picture: boolean | null = null,
    reheating_duration: number | null = null,
    cooling_duration: number | null = null,
    temperature_require_picture: boolean | null = null,
    encyclopedia_extension_active: boolean | null = null,
    origin: DocumentReference | null = null
  ) {
    super(ref);

    this.origin = origin;

    this.active = active;
    this.illustration_path = illustration_path;
    this.illustration_url = illustration_url;
    this.labels_module_active = labels_module_active;
    this.maximum_temperature = maximum_temperature;
    this.minimum_temperature = minimum_temperature;
    this.name = name;
    this.temperature_picture = temperature_picture;
    this.temperature_delivery_item = temperature_delivery_item;
    this.temperatures_module_active = temperatures_module_active;
    this.traceability_module_active = traceability_module_active;
    this.type = type;
    this.oils_module_active = oils_module_active;
    this.maximum_polarity = maximum_polarity;
    this.temperature_control_module_active = temperature_control_module_active;
    this.reheating_temperature = reheating_temperature;
    this.cooling_temperature = cooling_temperature;
    this.allergens = allergens;
    this.attachments = attachments;
    this.probe_id = probe_id;
    this.oils_require_picture = oils_require_picture;
    this.reheating_duration = reheating_duration;
    this.cooling_duration = cooling_duration;
    this.temperature_require_picture = temperature_require_picture;
    this.encyclopedia_extension_active = encyclopedia_extension_active;
  }

  public getAttachments() {
    if (this.originValue == null) {
      return this.attachments!;
    }
    if (this.attachments != null && !this.originValue.attachments_lock) {
      return this.attachments;
    } else {
      return this.originValue?.attachments!;
    }
  }
  public getEncyclopediaExtensionActive(): boolean | null {
    if (this.originValue == null) {
      return this.encyclopedia_extension_active!;
    }
    if (
      this.encyclopedia_extension_active != null &&
      !this.originValue.encyclopedia_extension_active_lock
    ) {
      return this.encyclopedia_extension_active;
    } else {
      return this.originValue?.encyclopedia_extension_active!;
    }
  }
  public getTemperatureRequirePicture(): boolean | null {
    if (this.originValue == null) {
      return this.temperature_require_picture!;
    }
    if (
      this.temperature_require_picture != null &&
      !this.originValue.temperature_require_picture_lock
    ) {
      return this.temperature_require_picture;
    } else {
      return this.originValue?.temperature_require_picture!;
    }
  }

  public override getReheatingDuration(): number | null {
    if (this.originValue == null) {
      return this.reheating_duration!;
    }
    if (
      this.reheating_duration != null &&
      !this.originValue.reheating_duration_lock
    ) {
      return this.reheating_duration;
    } else {
      return this.originValue?.reheating_duration!;
    }
  }
  public override getCoolingDuration(): number | null {
    if (this.originValue == null) {
      return this.cooling_duration!;
    }
    if (
      this.cooling_duration != null &&
      !this.originValue.cooling_duration_lock
    ) {
      return this.cooling_duration;
    } else {
      return this.originValue?.cooling_duration!;
    }
  }
  public getCoolingTemperature(): number | null {
    if (this.originValue == null) {
      return this.cooling_temperature!;
    }
    if (
      this.cooling_temperature != null &&
      !this.originValue.cooling_temperature_lock
    ) {
      return this.cooling_temperature;
    } else {
      return this.originValue?.cooling_temperature!;
    }
  }
  public getOilsRequirePicture(): boolean | null {
    if (this.originValue == null) {
      return this.oils_require_picture!;
    }
    if (
      this.oils_require_picture != null &&
      !this.originValue.require_oils_picture_lock
    ) {
      return this.oils_require_picture;
    } else {
      return this.originValue?.oils_require_picture!;
    }
  }

  public getReheatingTemperature(): number | null {
    if (this.originValue == null) {
      return this.reheating_temperature!;
    }
    if (
      this.reheating_temperature != null &&
      !this.originValue.reheating_temperature_lock
    ) {
      return this.reheating_temperature;
    } else {
      return this.originValue?.reheating_temperature!;
    }
  }

  public getTemperatureControlModuleActive(): boolean | null {
    if (this.originValue == null) {
      return this.temperature_control_module_active!;
    }
    if (
      this.temperature_control_module_active != null &&
      !this.originValue.temperature_control_module_active_lock
    ) {
      return this.temperature_control_module_active;
    } else {
      return this.originValue?.temperature_control_module_active!;
    }
  }

  public override getActive(): boolean | null {
    if (this.originValue == null) {
      return this.active!;
    }
    if (this.active != null && !this.originValue.active_lock) {
      return this.active;
    } else {
      return this.originValue?.active!;
    }
  }
  public override getTemperaturesModuleActive(): boolean {
    if (this.originValue == null) {
      return this.temperatures_module_active!;
    }
    if (
      this.temperatures_module_active != null &&
      !this.originValue.temperatures_module_active_lock
    ) {
      return this.temperatures_module_active;
    } else {
      return this.originValue?.temperatures_module_active!;
    }
  }

  public override getOilsModuleActive(): boolean {
    if (this.originValue == null) {
      return this.oils_module_active!;
    }
    if (
      this.oils_module_active != null &&
      !this.originValue.oils_module_active_lock
    ) {
      return this.oils_module_active;
    } else {
      return this.originValue?.oils_module_active!;
    }
  }

  public override getTraceabilityModuleActive(): boolean {
    if (this.originValue == null) {
      return this.traceability_module_active!;
    }
    if (
      this.traceability_module_active != null &&
      !this.originValue.traceability_module_active_lock
    ) {
      return this.traceability_module_active;
    } else {
      return this.originValue?.traceability_module_active!;
    }
  }

  public override getLabelsModuleActive(): boolean {
    if (this.originValue == null) {
      return this.labels_module_active!;
    }
    if (
      this.labels_module_active != null &&
      !this.originValue.labels_module_active_lock
    ) {
      return this.labels_module_active;
    } else {
      return this.originValue?.labels_module_active!;
    }
  }

  public override getIllustrationUrl(): string | null {
    if (this.originValue == null) {
      return this.illustration_url!;
    }
    if (this.illustration_url != null && !this.originValue.illustration_lock) {
      return this.illustration_url;
    } else {
      return this.originValue?.illustration_url!;
    }
  }
  public override getName(): string | null {
    if (this.originValue == null) {
      return this.name!;
    }
    if (this.name != null && !this.originValue.name_lock) {
      return this.name;
    } else {
      return this.originValue?.name!;
    }
  }
  public override getMinimumTemperature(): number | null {
    if (this.originValue == null) {
      return this.minimum_temperature!;
    }
    if (
      this.minimum_temperature != null &&
      !this.originValue.minimum_temperature_lock
    ) {
      return this.minimum_temperature;
    } else {
      return this.originValue?.minimum_temperature!;
    }
  }
  public override getMaximumTemperature(): number | null {
    if (this.originValue == null) {
      return this.maximum_temperature!;
    }
    if (
      this.maximum_temperature != null &&
      !this.originValue.maximum_temperature_lock
    ) {
      return this.maximum_temperature;
    } else {
      return this.originValue?.maximum_temperature!;
    }
  }
  public override getMaximumPolarity(): number | null {
    if (this.originValue == null) {
      return this.maximum_polarity!;
    }
    if (
      this.maximum_polarity != null &&
      !this.originValue.maximum_polarity_lock
    ) {
      return this.maximum_polarity;
    } else {
      return this.originValue?.maximum_polarity!;
    }
  }
  public getType() {
    if (this.originValue == null) {
      return this.type!;
    }
    if (this.type != null && !this.originValue.type_lock) {
      return this.type;
    } else {
      return this.originValue?.type!;
    }
  }

  public getIllustrationPath(): string | null {
    if (this.originValue == null) {
      return this.illustration_path!;
    }
    if (this.illustration_path != null && !this.originValue.illustration_lock) {
      return this.illustration_path;
    } else {
      return this.originValue?.illustration_path!;
    }
  }
  public getTemperaturePicture(): boolean | null {
    if (this.originValue == null) {
      return this.temperature_picture!;
    }
    if (
      this.temperature_picture != null &&
      !this.originValue.temperature_picture_lock
    ) {
      return this.temperature_picture;
    } else {
      return this.originValue?.temperature_picture!;
    }
  }
  public getTemperatureDeliveryItem(): boolean | null {
    if (this.originValue == null) {
      return this.temperature_delivery_item!;
    }
    if (
      this.temperature_delivery_item != null &&
      !this.originValue.temperature_delivery_item_lock
    ) {
      return this.temperature_delivery_item;
    } else {
      return this.originValue?.temperature_delivery_item!;
    }
  }

  public getAllergens(): AllergenEnum[] | null {
    if (this.originValue === null) {
      return this.allergens;
    }
    if (this.allergens != null && !this.originValue.allergens_lock) {
      return this.allergens;
    } else {
      return this.originValue?.allergens;
    }
  }

  public static fromOrigin(origin: DatabaseElement, store: Store) {
    let element = new StoreElement(
      doc(collection(Firebase.firestore, store.ref.path, "elements")),
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      origin.ref
    );
    element.setOriginValue(origin);
    return element;
  }
  public override isLocal(): boolean {
    return this.origin == null;
  }
  public static fromFirestore(snapshot: DocumentSnapshot): StoreElement {
    const data = snapshot.data()!;

    let result = new StoreElement(
      snapshot.ref,
      SecureatSnapshot.getNullableValue<boolean>(data, "active", Boolean),
      SecureatSnapshot.getNullableValue<string>(
        data,
        "illustration_path",
        String
      ),
      SecureatSnapshot.getNullableValue<string>(
        data,
        "illustration_url",
        String
      ),
      SecureatSnapshot.getNullableValue<boolean>(
        data,
        "labels_module_active",
        Boolean
      ),
      SecureatSnapshot.getNullableValue<number>(
        data,
        "maximum_temperature",
        Number
      ),
      SecureatSnapshot.getNullableValue<number>(
        data,
        "minimum_temperature",
        Number
      ),
      SecureatSnapshot.getNullableValue<string>(data, "name", String),
      SecureatSnapshot.getNullableValue<boolean>(
        data,
        "temperature_picture",
        Boolean
      ),
      SecureatSnapshot.getNullableValue<boolean>(
        data,
        "temperature_delivery_item",
        Boolean
      ),

      SecureatSnapshot.getNullableValue<boolean>(
        data,
        "temperatures_module_active",
        Boolean
      ),
      SecureatSnapshot.getNullableValue<boolean>(
        data,
        "traceability_module_active",
        Boolean
      ),
      SecureatSnapshot.getNullableValue<DocumentReference>(
        data,
        "type",
        DocumentReference
      ),
      SecureatSnapshot.getNullableValue<boolean>(
        data,
        "oils_module_active",
        Boolean
      ),
      SecureatSnapshot.getNullableValue<number>(
        data,
        "maximum_polarity",
        Number
      ),
      SecureatSnapshot.getNullableValue<number>(
        data,
        "reheating_temperature",
        Number
      ),
      SecureatSnapshot.getNullableValue<boolean>(
        data,
        "temperature_control_module_active",
        Boolean
      ),
      SecureatSnapshot.getNullableValue<number>(
        data,
        "cooling_temperature",
        Number
      ),
      SecureatSnapshot.getValue<[]>(data, "allergens", []),
      data.attachments == undefined
        ? []
        : data.attachments.map((x: any) => Attachment.fromFirestore(x)),

      SecureatSnapshot.getNullableValue<string>(data, "probe_id", String),
      SecureatSnapshot.getNullableValue<boolean>(
        data,
        "oils_require_picture",
        Boolean
      ),
      SecureatSnapshot.getNullableValue<number>(
        data,
        "reheating_duration",
        Number
      ),
      SecureatSnapshot.getNullableValue<number>(
        data,
        "cooling_duration",
        Number
      ),
      SecureatSnapshot.getNullableValue<boolean>(
        data,
        "temperature_require_picture",
        Boolean
      ),
      SecureatSnapshot.getNullableValue<boolean>(
        data,
        "encyclopedia_extension_active",
        Boolean
      ),
      SecureatSnapshot.getNullableValue<DocumentReference>(
        data,
        "origin",
        DocumentReference
      )
    );

    return result;
  }
  public toFirestore(): any {
    return {
      active: this.active,
      illustration_path: this.illustration_path,
      illustration_url: this.illustration_url,
      labels_module_active: this.labels_module_active,
      maximum_temperature: this.maximum_temperature,
      minimum_temperature: this.minimum_temperature,
      name: this.name,
      origin: this.origin,
      temperature_picture: this.temperature_picture,
      temperature_delivery_item: this.temperature_delivery_item,
      temperatures_module_active: this.temperatures_module_active,
      traceability_module_active: this.traceability_module_active,
      type: this.type,
      oils_module_active: this.oils_module_active,
      maximum_polarity: this.maximum_polarity,
      temperature_control_module_active: this.temperature_control_module_active,
      reheating_temperature: this.reheating_temperature,
      cooling_temperature: this.cooling_temperature,
      allergens: this.allergens,
      attachments:
        this.attachments == undefined
          ? []
          : this.attachments?.map((x) => x.toFirestore()),
      oils_require_picture: this.oils_require_picture,
      probe_id: this.probe_id,
      reheating_duration: this.reheating_duration,
      cooling_duration: this.cooling_duration,
      encyclopedia_extension_active: this.encyclopedia_extension_active,
      temperature_require_picture: this.temperature_require_picture,
    };
  }

  public setOriginValue(origin: DatabaseElement) {
    this.originValue = origin;
  }

  static duplicate(el: StoreElement, cloneIllustration: boolean) {
    return new StoreElement(
      doc(collection(Firebase.firestore, el.ref.parent.path)),
      el.getActive(),
      cloneIllustration ? el.getIllustrationPath() : null,
      cloneIllustration ? el.getIllustrationUrl() : null,
      el.getLabelsModuleActive()!,
      el.getMaximumTemperature()!,
      el.getMinimumTemperature()!,
      el.getName()!,
      el.getTemperaturePicture(),
      el.getTemperatureDeliveryItem(),
      el.getTemperaturesModuleActive(),
      el.getTraceabilityModuleActive(),
      el.getType(),
      el.getOilsModuleActive(),
      el.getMaximumPolarity(),
      el.getReheatingTemperature(),
      el.getTemperatureControlModuleActive(),
      el.getCoolingTemperature(),
      el.getAllergens(),
      el.getAttachments(),
      el.probe_id,
      el.getOilsRequirePicture(),
      el.getReheatingDuration(),
      el.getCoolingDuration(),
      el.getTemperatureRequirePicture(),
      el.getEncyclopediaExtensionActive(),
      null
    );
  }
  static clone(el: StoreElement) {
    let result = new StoreElement(
      el.ref,
      el.active,
      el.illustration_path,
      el.illustration_url,
      el.labels_module_active,
      el.maximum_temperature,
      el.minimum_temperature,
      el.name,
      el.temperature_picture,
      el.temperature_delivery_item,
      el.temperatures_module_active,
      el.traceability_module_active,
      el.type,
      el.oils_module_active,
      el.maximum_polarity,
      el.reheating_temperature,
      el.temperature_control_module_active,
      el.cooling_temperature,
      el.allergens,
      el.attachments,
      el.probe_id,
      el.oils_require_picture,
      el.reheating_duration,
      el.cooling_duration,
      el.temperature_require_picture,
      el.encyclopedia_extension_active,
      el.origin
    );

    result.isRemote = el.isRemote;
    result.setOriginValue(el.originValue!);

    return result;
  }
}
