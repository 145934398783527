
import * as Lang from "@/i18n/lang"; //  @change="clearAutofill"
import { defineComponent, getCurrentInstance, nextTick } from "vue";
import { ref } from "vue";
import { getState } from "@/pinia/AppState";
import { getCommunicationsState } from "@/pinia/communications/CommunicationsState";
import * as DOMUtils from "@/utils/DOMUtils";

export default defineComponent({
  expose: ["updateGrid", "setSearch", "getSearch", "applySearch"],
  emits: ["update:modelValue"],
  props: [
    "btnText",
    "newCallback",
    "searchChange",
    "noToggle",
    "placeholder",
    "calm",
    "modelValue",
    "targetGridId",
  ],
  data() {
    return {
      Lang: Lang,
      state: getState(),
      displayUnactives: this.modelValue,
    };
  },

  mounted() { },
  methods: {
    async newClicked() {
      var refs = <any>this.$refs;
      await this.newCallback();
    },
    getPlaceholder() {
      if (this.placeholder != undefined) {
        return this.placeholder;
      }
      return Lang.getI18N("search");
    },
    clearAutofill() {
      (<any>this.$refs).searchInput.value = "";
    },
    setSearch(value: string) {
      let refs = <any>this.$refs;
      refs.searchInput.value = value;
      this.onSearchChange();
    },
    getSearch(): string {
      let refs = <any>this.$refs;
      return refs.searchInput.value;
    },
    onDisplayAllChange(flag: boolean) {
      this.$emit("update:modelValue", flag);
    },
    applySearch() {
      this.onSearchChange();
    },
    onSearchChange() {
      let refs = <any>this.$refs;
      let search = refs.searchInput.value;

      if (this.searchChange != undefined) {
        this.searchChange(search);
      }

      if (this.calm != undefined) {
        return;
      }

      let grid = document.getElementById(this.targetGridId);

      if (!grid) {
        console.log("Grid not found for search bar");
        return;
      }
      for (let i = 0; i < grid!.childNodes.length; i++) {
        let card = grid!.childNodes[i] as HTMLElement;
        if (card.tagName == "DIV") {
          if (card.textContent!.toLowerCase().includes(search.toLowerCase())) {
            card.style.display = "";
          } else {
            card.style.display = "none";
          }
        }
      }
    },
  },
  watch: {
    displayUnactives(newValue, oldValue) {
      this.onDisplayAllChange(newValue);
      this.$nextTick(() => {
        this.onSearchChange();
      });
    },
  },
});
