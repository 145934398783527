
import User, { Role } from "@/model/User";
import * as Lang from "@/i18n/lang";
import { getStoreDatabaseState } from "@/pinia/databases/StoreDatabaseState";
import { defineComponent } from "vue";
import * as Storage from "@/firebase/Storage";
import SecureatIllustration from "@/model/utils/SecureatIllustration";
import * as Snackbars from "@/utils/Snackbars";
import * as ModuleManager from "@/firebase/ModuleManager";
import { ModuleEnum } from "@/model/ModuleEnum";
import { ExtensionEnum } from "@/model/ExtensionEnum";
import { getState } from "@/pinia/AppState";
import { getAllergensState } from "@/pinia/allergens/AllergensState";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import { getStoreManager } from "@/firebase/StoreManager";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import * as MailManager from "@/firebase/MailManager";
import { getUserManager } from "@/firebase/UserManager";
import * as Modals from "@/utils/Modals";
import { KooveaSticker } from "@/model/Koovea/KooveaSticker";
import ProbeList from "@/components/probes/ProbeList.vue";
import { getProbesManager } from "@/firebase/ProbeManager";
import SeLoadingIndicator from "../global/SeLoadingIndicator.vue";

export default defineComponent({
  expose: ["onTabOpen"],
  components: {
    ProbeList,
  },
  data() {
    return {
      Role: Role,
      Lang: Lang,
      loading: false,
    };
  },

  setup() {
    var state = getStoreDatabaseState();
    return {
      toggleRight: getState().customStoreId == null,
      role: getState().userData?.role,
      state: state,
    };
  },
  methods: {
    async save() {
      var refs = <any>this.$refs;

      if (
        this.state.selectedSticker != null &&
        this.state.store.koovea_sticker == null
      ) {
        this.state.store.koovea_sticker = this.state.selectedSticker.id;
      }

      await this.state.store.set();
      Snackbars.display("Les modifications ont été enregistrées.");
    },
    async onTabOpen() {
      this.loading = true;
      this.state.probes = await getProbesManager().getProbeOfStores(
        this.state.store?.ref.id!
      );

      if (this.state.store.distributor) {
        console.log("ok");
        this.state.kooveaStickers = await SecureatServerApi.getKooveaStickers(
          this.state.store.distributor.id,
          this.state.store.ref.id
        );

        if (this.state.store.koovea_sticker != null) {
          let sticker = this.state.kooveaStickers.find(
            (x) => x.id == this.state.store.koovea_sticker
          );

          if (sticker) {
            this.state.selectedSticker = sticker;
          } else {
            console.warn(
              "Hub " + this.state.store.koovea_sticker + " not found."
            );
          }
        } else {
          this.state.selectedSticker = null;
        }
      }
      this.loading = false;
    },
    hasMasterRights() {
      let userRole = getState().userData!.role;
      return userRole == "admin" || userRole == "distributor";
    },
  },
});
