import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-408e45e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex col" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "main-layout mobile-background" }
const _hoisted_4 = {
  key: 0,
  class: "container"
}
const _hoisted_5 = { class: "flex col" }
const _hoisted_6 = { class: "flex col" }
const _hoisted_7 = { class: "flex grow align-center justify-between" }
const _hoisted_8 = { class: "expend-div no-mobile" }
const _hoisted_9 = { ref: "traceabilityPanel" }
const _hoisted_10 = { class: "table-list" }
const _hoisted_11 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_12 = { ref: "temperaturePanel" }
const _hoisted_13 = { class: "table-list" }
const _hoisted_14 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_15 = { ref: "temperatureControlPanel" }
const _hoisted_16 = { class: "table-list" }
const _hoisted_17 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_18 = { ref: "oilsPanel" }
const _hoisted_19 = { class: "table-list" }
const _hoisted_20 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_21 = { ref: "cleaningPanel" }
const _hoisted_22 = { class: "table-list" }
const _hoisted_23 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_24 = {
  key: 1,
  class: "container p24"
}
const _hoisted_25 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_SeModal = _resolveComponent("SeModal")!
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_ModuleSearchResult = _resolveComponent("ModuleSearchResult")!
  const _component_SeSimpleTab = _resolveComponent("SeSimpleTab")!
  const _component_SeMultiFilter = _resolveComponent("SeMultiFilter")!
  const _component_TraceabilityResultTable = _resolveComponent("TraceabilityResultTable")!
  const _component_TemperatureResultTable = _resolveComponent("TemperatureResultTable")!
  const _component_TemperatureControlTable = _resolveComponent("TemperatureControlTable")!
  const _component_OilResultTable = _resolveComponent("OilResultTable")!
  const _component_ActionResultTable = _resolveComponent("ActionResultTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SeModal, {
      title: "Infos",
      ref: "infoModal"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("ask-domhygiene")), 1),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_SeButton, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("close")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_SeButton, null, {
              default: _withCtx(() => [
                _createTextVNode("Ouvrir le dashboard Domhygiène")
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    }, 512),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_NavHeader, {
        title: _ctx.Lang.getI18N('history')
      }, null, 8, ["title"]),
      (_ctx.visible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_ModuleSearchResult, {
                onStoreChange: _ctx.onStoreChange,
                ref: "search"
              }, null, 8, ["onStoreChange"]),
              _withDirectives(_createElementVNode("div", _hoisted_6, [
                _createVNode(_component_SeSimpleTab, {
                  ref: "tab",
                  onSwitch: _ctx.onTabChange,
                  labels: [
            _ctx.Lang.getI18N('temperatures'),
            _ctx.Lang.getI18N('traceability'),
            _ctx.Lang.getI18N('oils'),
            _ctx.Lang.getI18N('action'),
            _ctx.Lang.getI18N('temperature_control'),
          ],
                  icons: [
            '/ico/temperature.svg',
            '/ico/camera.svg',
            '/ico/oils.svg',
            '/ico/task_action.svg',
            '/ico/temperature_control.svg',
          ]
                }, null, 8, ["onSwitch", "labels"]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_SeButton, {
                    ref: "exportBtn",
                    onPress: _ctx.exportPdf,
                    icon: "ico/export.svg"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("export")) + " PDF ", 1)
                    ]),
                    _: 1
                  }, 8, ["onPress"]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_SeMultiFilter, {
                      ref: "multiFilter",
                      singleSelect: true,
                      titleFunc: (item) => _ctx.Lang.getI18N(item),
                      selectionChanged: _ctx.onFilterChange,
                      items: [
                  _ctx.HistoryFilter.Completed,
                  _ctx.HistoryFilter.Anomaly,
                  _ctx.HistoryFilter.All,
                ]
                    }, null, 8, ["titleFunc", "selectionChanged", "items"]),
                    _createElementVNode("span", {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleExpend())),
                      class: "expend-btn no-mobile"
                    }, _toDisplayString(_ctx.expend ? _ctx.Lang.getI18N("reduce") : _ctx.Lang.getI18N("expend")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    (_ctx.traceabilityTasks.length == 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.Lang.getI18N("no-results")), 1))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.traceabilityTasks, (task) => {
                      return (_openBlock(), _createBlock(_component_TraceabilityResultTable, {
                        store: _ctx.getCurrentStore(),
                        taskWrapper: task
                      }, null, 8, ["store", "taskWrapper"]))
                    }), 256))
                  ])
                ], 512),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    (_ctx.temperatureTasks.length == 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.Lang.getI18N("no-results")), 1))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.temperatureTasks, (task) => {
                      return (_openBlock(), _createBlock(_component_TemperatureResultTable, {
                        store: _ctx.getCurrentStore(),
                        taskWrapper: task
                      }, null, 8, ["store", "taskWrapper"]))
                    }), 256))
                  ])
                ], 512),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    (_ctx.temperatureControlTasks.length == 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.Lang.getI18N("no-results")), 1))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.temperatureControlTasks, (task) => {
                      return (_openBlock(), _createBlock(_component_TemperatureControlTable, {
                        store: _ctx.getCurrentStore(),
                        taskWrapper: task
                      }, null, 8, ["store", "taskWrapper"]))
                    }), 256))
                  ])
                ], 512),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    (_ctx.oilTasks.length == 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.Lang.getI18N("no-results")), 1))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oilTasks, (task) => {
                      return (_openBlock(), _createBlock(_component_OilResultTable, {
                        store: _ctx.getCurrentStore(),
                        taskWrapper: task
                      }, null, 8, ["store", "taskWrapper"]))
                    }), 256))
                  ])
                ], 512),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    (_ctx.cleaningTasks.length == 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.Lang.getI18N("no-results")), 1))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cleaningTasks, (task) => {
                      return (_openBlock(), _createBlock(_component_ActionResultTable, {
                        store: _ctx.getCurrentStore(),
                        taskWrapper: task
                      }, null, 8, ["store", "taskWrapper"]))
                    }), 256))
                  ])
                ], 512)
              ], 512), [
                [_vShow, _ctx.completeDisplay]
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_24, [
            _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.Lang.getI18N("no-store-in-history")), 1)
          ]))
    ])
  ], 64))
}