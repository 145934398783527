import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";

export default class EmployeeRole extends SecureatSnapshot {
  name: string = "";
  active: boolean = false;
  features_permissions: Map<string, any> = new Map<string, any>();

  constructor(
    ref: DocumentReference,
    name: string,
    active: boolean,
    features_permissions: Map<string, any> = new Map<string, any>()
  ) {
    super(ref);
    this.name = name;
    this.active = active;
    this.features_permissions = features_permissions;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): EmployeeRole {
    const data = snapshot.data()!;

    let result = new EmployeeRole(
      snapshot.ref,
      data.name,
      data.active,
      data.features_permissions == undefined ||
        data.features_permissions == null
        ? null
        : new Map(Object.entries(data.features_permissions))
    );
    return result;
  }

  public toFirestore() {
    return {
      name: this.name,
      active: this.active,
      features_permissions: this.features_permissions
        ? Object.fromEntries(this.features_permissions)
        : null,
    };
  }
}
