import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-418b4ffe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal flex",
  ref: "modal"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header align-center" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = {
  class: "text",
  style: {"color":"white"}
}
const _hoisted_6 = {
  ref: "body",
  class: "modal-body text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.title), 1)
          ]),
          _createElementVNode("span", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close())),
            class: "close"
          }, "×")
        ], 512), [
          [_vShow, !_ctx.noHeader]
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 512)
      ])
    ], 512)
  ]))
}