import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-65cc4a10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex align-center" }
const _hoisted_2 = {
  key: 0,
  class: "align-center justify-center flex grow"
}
const _hoisted_3 = {
  key: 0,
  class: "align-center justify-center flex grow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_StoreTabGeneral = _resolveComponent("StoreTabGeneral")!
  const _component_ReadonlyField = _resolveComponent("ReadonlyField")!
  const _component_SeLoadingIndicator = _resolveComponent("SeLoadingIndicator")!
  const _component_ElementList = _resolveComponent("ElementList")!
  const _component_TaskConfigurationList = _resolveComponent("TaskConfigurationList")!
  const _component_StoreTabEmployee = _resolveComponent("StoreTabEmployee")!
  const _component_StoreTabKoovea = _resolveComponent("StoreTabKoovea")!
  const _component_StoreTabDevices = _resolveComponent("StoreTabDevices")!
  const _component_SeTab = _resolveComponent("SeTab")!

  return (_openBlock(), _createElementBlock("div", {
    onScroll: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onScroll && _ctx.onScroll(...args))),
    ref: "mainLayout",
    class: "main-layout"
  }, [
    _createVNode(_component_NavHeader, {
      backButton: "true",
      subtitle: _ctx.state.store!.name,
      title: _ctx.state.database!.name
    }, null, 8, ["subtitle", "title"]),
    _createVNode(_component_SeTab, {
      onTabChange: _ctx.onTabChange,
      ref: "tab",
      tabs: [
        _ctx.Lang.getI18N('general'),
        _ctx.Lang.getI18N('elements'),
        _ctx.Lang.getI18N('tasks'),
        _ctx.Lang.getI18N('employees'),
        _ctx.Lang.getI18N('probes'),
        _ctx.Lang.getI18N('devices'),
      ],
      icons: [
        'ico/stores-gray.svg',
        '/ico/element-gray.svg',
        '/ico/tasks.svg',
        'ico/employees-gray.svg',
        'ico/probe-gray.svg',
        'ico/device-gray.svg',
      ]
    }, {
      sticky: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.state.tabIndex == 1 || _ctx.state.tabIndex == 2)
            ? (_openBlock(), _createBlock(_component_SearchBar, {
                key: 0,
                searchChange: 
              _ctx.state.tabIndex == 1 ? _ctx.onElementSearchChange : _ctx.onTaskSearchChange
            ,
                targetGridId: _ctx.state.tabIndex == 1 ? 'elementGrid' : 'taskGrid',
                noToggle: true,
                btnRedirect: "newElement",
                newCallback: _ctx.onNewClicked,
                btnText: 
              _ctx.state.tabIndex == 1
                ? _ctx.Lang.getI18N('new-element')
                : _ctx.Lang.getI18N('new-task')
            ,
                class: "grow",
                ref: "search",
                modelValue: _ctx.state.displayUnactives,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.displayUnactives) = $event))
              }, null, 8, ["searchChange", "targetGridId", "newCallback", "btnText", "modelValue"]))
            : _createCommentVNode("", true),
          (
              (_ctx.state.tabIndex == 1 || _ctx.state.tabIndex == 2) &&
              _ctx.user.role != _ctx.Role.Customer
            )
            ? (_openBlock(), _createBlock(_component_SeButton, {
                key: 1,
                class: "no-mobile",
                icon: "/ico/expert.svg",
                onPress: _ctx.openBulk
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Mode expert ")
                ]),
                _: 1
              }, 8, ["onPress"]))
            : _createCommentVNode("", true)
        ])
      ]),
      "0": _withCtx(() => [
        _createVNode(_component_ReadonlyField, {
          locked: _ctx.readonly,
          class: "general-container"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_StoreTabGeneral, { ref: "generalTab" }, null, 512)
          ]),
          _: 1
        }, 8, ["locked"])
      ]),
      "1": _withCtx(() => [
        (!_ctx.elementsLoaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_SeLoadingIndicator)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_ReadonlyField, {
          locked: _ctx.readonly,
          style: {"background-color":"#f3fafa"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElementList, {
              storeDatabase: true,
              elements: _ctx.state.elements,
              database: _ctx.state.database,
              store: _ctx.state.store!,
              unactives: _ctx.state.displayUnactives
            }, null, 8, ["elements", "database", "store", "unactives"])
          ]),
          _: 1
        }, 8, ["locked"])
      ]),
      "2": _withCtx(() => [
        (!_ctx.tasksLoaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_SeLoadingIndicator)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_ReadonlyField, {
          locked: _ctx.readonly,
          style: {"background-color":"#f3fafa"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TaskConfigurationList, {
              storeDatabase: true,
              tasks: _ctx.state.tasks,
              database: _ctx.state.database,
              store: _ctx.state.store!,
              unactives: _ctx.state.displayUnactives
            }, null, 8, ["tasks", "database", "store", "unactives"])
          ]),
          _: 1
        }, 8, ["locked"])
      ]),
      "3": _withCtx(() => [
        _createVNode(_component_ReadonlyField, {
          locked: _ctx.readonly,
          class: "general-container",
          style: {"background-color":"transparent"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_StoreTabEmployee, { ref: "employeeTab" }, null, 512)
          ]),
          _: 1
        }, 8, ["locked"])
      ]),
      "4": _withCtx(() => [
        _createVNode(_component_ReadonlyField, {
          locked: _ctx.readonly,
          class: "general-container"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_StoreTabKoovea, { ref: "kooveaTab" }, null, 512)
          ]),
          _: 1
        }, 8, ["locked"])
      ]),
      "5": _withCtx(() => [
        _createVNode(_component_ReadonlyField, {
          locked: _ctx.readonly,
          class: "general-container"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_StoreTabDevices, { ref: "deviceTab" }, null, 512)
          ]),
          _: 1
        }, 8, ["locked"])
      ]),
      _: 1
    }, 8, ["onTabChange", "tabs"])
  ], 544))
}