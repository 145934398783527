
import {
  createApp,
  createBaseVNode,
  createVNode,
  defineComponent,
  h,
  render,
} from "vue";
import * as Lang from "@/i18n/lang";
import SeSnackbar from "@/components/global/SeSnackbar.vue";
import { Vue } from "vue-class-component";
import * as Snackbars from "@/utils/Snackbars";
import * as Modals from "@/utils/Modals";
import { getState as getAppState, getState } from "@/pinia/AppState";
import SeProgressBar from "@/components/global/SeProgressBar.vue";
import * as StoreManager from "@/firebase/StoreManager";
import Store from "@/model/Store";
import SeCheckBox from "@/components/global/SeCheckBox.vue";
import { Role } from "@/model/User";
import AdminHome from "@/components/home/AdminHome.vue";
import EquipementTemperatures from "@/components/home/EquipementTemperatures.vue";
import Tasks from "@/components/home/Tasks.vue";
import Employee from "@/model/Employees/Employee";
import EmployeeSchedule from "@/components/home/EmployeeSchedule.vue";
import RestaurantScore from "@/components/home/RestaurantScore.vue";
import CommunicationAndTraining from "@/components/home/CommunicationAndTraining.vue";
import SeDropdown from "@/components/global/dropdown/SeDropdown.vue";
import { getHomeState } from "@/pinia/home/HomeState";
import SeLoadingIndicator from "@/components/global/SeLoadingIndicator.vue";
import PunchCardsView from "./home/PunchCardsView.vue";
import DropdownItem from "@/components/newGlobal/Dropdowns/DropdownItem.vue";
import DatabaseStoreSelection from "@/components/home/DatabaseStoreSelection.vue";
import Audits from "@/components/home/Audits.vue";
import BestStores from "@/components/home/BestStores.vue";

export default defineComponent({
  components: {
    SeProgressBar,
    PunchCardsView,
    SeCheckBox,
    AdminHome,
    EquipementTemperatures,
    Tasks,
    EmployeeSchedule,
    RestaurantScore,
    DatabaseStoreSelection,
    Audits,
    CommunicationAndTraining,
    BestStores,
  },
  data() {
    return {
      Role: Role,
      Lang: Lang,
    };
  },
  async setup(props, ctx) {
    var state = getState();
    var homeState = getHomeState();

    await homeState.setup();

    {
      return {
        state,
        role: state.userData?.role!,
        homeState,
      };
    }
  },

  methods: {},
});
