
import { defineComponent } from "vue";
import SeSnackbar from "./SeSnackbar.vue";
import { HalfCircleSpinner } from "epic-spinners";

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  components: {
    SeSnackbar,
    HalfCircleSpinner,
  },
});
