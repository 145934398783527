
import { defineComponent } from "@vue/runtime-core";
import * as TaskManager from "@/firebase/TaskManager";
import Task from "@/model/Task";
import Store from "@/model/Store";
import CleaningResult from "@/model/TaskResults/CleaningResult";
import TaskStatus from "../TaskStatus.vue";
import * as Lang from "@/i18n/lang";
import PostponedTaskRow from "./PostponedTaskRow.vue";
import ActionResultRow from "./ActionResultRow.vue";
import * as TaskActionTypeManager from "@/firebase/TaskTypeManager";
import TaskWithResult from "@/pinia/tasks/TaskWithResult";
import * as DateUtils from "@/utils/DateUtils";
export default defineComponent({
  props: {
    taskWrapper: {
      type: TaskWithResult,
      required: true,
    },
    store: {
      type: Store,
    },
  },

  methods: {},
  async setup(props) {
    let total = props.taskWrapper.results.length;
    let dones = 0;
    for (let result of props.taskWrapper.results) {
      if ((<CleaningResult>result).done) {
        dones++;
      }
    }

    return {
      dones: dones,
      total: total,
    };
  },
  data() {
    return {
      TaskManager: TaskManager,
      Lang: Lang,
      DateUtils: DateUtils,
    };
  },
  components: {
    TaskStatus,
    PostponedTaskRow,
    ActionResultRow,
  },
});
