
import { defineComponent } from "@vue/runtime-core";
import * as TaskManager from "@/firebase/TaskManager";
import TemperatureControlResultRow from "@/components/tasks/table/TemperatureControlResultRow.vue";
import Task from "@/model/Task";
import Store from "@/model/Store";
import * as Lang from "@/i18n/lang";
import TaskStatus from "../TaskStatus.vue";
import TemperatureControlResult from "@/model/TaskResults/TemperatureControlResult";
import TaskWithResult from "@/pinia/tasks/TaskWithResult";
import * as DateUtils from "@/utils/DateUtils";

export default defineComponent({
  props: {
    taskWrapper: {
      type: TaskWithResult,
      required: true,
    },
    store: {
      type: Store,
    },
  },

  expose: ["expendable"],

  methods: {},

  async mounted() {
    if (this.taskWrapper.results.length > 0)
      this.mode = this.taskWrapper.results[0].mode!;

    for (let result of this.taskWrapper.results) {
      if (result.mode == "cooling") {
        if (result.end_temperature! > result.target_temperature!) {
          this.hasInvalidResults = true;
          break;
        }
      } else if (result.mode == "reheating") {
        if (result.end_temperature! < result.target_temperature!) {
          this.hasInvalidResults = true;
          break;
        }
      }
    }
  },

  data() {
    let hasInvalidResults = false;
    return {
      hasInvalidResults: hasInvalidResults,
      TaskManager: TaskManager,
      Lang: Lang,
      mode: "",
      DateUtils: DateUtils,
      results: [] as TemperatureControlResult[],
    };
  },
  components: {
    TemperatureControlResultRow,
    TaskStatus,
  },
});
