
import { getHomeState } from "@/pinia/home/HomeState";
import { defineComponent } from "vue";
import * as DateUtils from "@/utils/DateUtils";
import SeProgressBar from "../global/SeProgressBar.vue";
import { getCommunicationStatsState } from "@/pinia/communications/CommunicationStatsState";
import { getState } from "@/pinia/NavigationState";
import Communication from "@/model/Communication/Communication";
import Employee from "@/model/Employees/Employee";
import CommunicationResult from "@/model/Communication/CommunicationResult";
import Training from "@/model/Trainings/Training";
import { getTrainingStatsState } from "@/pinia/trainings/TrainingStatsState";
import TrainingResult from "@/model/Trainings/TrainingResult";
import * as Lang from "@/i18n/lang";
import { getAuditManager } from "@/firebase/AuditManager";
import { Role } from "@/model/User";
import Audit from "@/model/Auditing/Audit";
import Card from "../newGlobal/Card.vue";
import { getStoreManager } from "@/firebase/StoreManager";

export default defineComponent({
    name: "Best Stores",
    data() {
        return {
            Lang: Lang,
            DateUtils: DateUtils,
        };
    },
    async setup() {
        var state = getHomeState();


        var stores = await getStoreManager().getStoresFromDatabase(state.selectedDatabase);

        console.log(stores);
        stores = stores.sort((a, b) => b.score - a.score);

        var topStores = stores.slice(0, 5);

        topStores = topStores.filter(x => x.score != null);

        return {
            topStores: topStores,
            homeState: getHomeState(),
        };
    },
    methods: {
        onWheel(event: WheelEvent) {
            // Prevent default vertical scroll
            event.preventDefault();

            // Scroll horizontally by the amount the user tried to scroll vertically
            const container = event.currentTarget as HTMLElement;
            container.scrollLeft += event.deltaY;
        },
    },
});
