import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  setDoc,
  Firestore,
  and,
  or,
} from "firebase/firestore";

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import { getState } from "../pinia/AppState";
import * as Firebase from "@/firebase/Firebase";
import User from "../model/User";
import Store from "../model/Store";
import { Role } from "../model/User";
import { getStoreManager } from "@/firebase/StoreManager";
import { getFunctions, httpsCallable } from "firebase/functions";
import Database from "@/model/Database";
import { InvitationType } from "@/model/Invitation";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import * as ModuleManager from "@/firebase/ModuleManager";
import Distributor from "@/model/Distributor";
import * as SecureatServerApi from "@/utils/SecureatServerApi";

import {
  addDoc,
  documentId,
  arrayUnion,
  DocumentReference,
  limit,
  Query,
  DocumentData,
} from "firebase/firestore";

import Element from "../model/Elements/Element";
import * as DistributorManager from "@/firebase/DistributorManager";
import * as UserManager from "@/firebase/UserManager";
import { defineStore } from "pinia";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { getDistributorManager } from "@/firebase/DistributorManager";
import { getDatabaseManager } from "./DatabaseManager";

interface UserManager {
  listener: SnapshotListener<User> | null;
}

export const getUserManager = defineStore("UserManager", {
  state: (): UserManager => {
    return {
      listener: null,
    };
  },
  actions: {
    async initialize() {
      if (this.listener != null) {
        return;
      }

      let user = getState().userData!;

      let q: Query<DocumentData>;

      if (user.role == Role.Admin) {
        q = query(collection(Firebase.firestore, "users"));
      }
      if (user.role == Role.Distributor) {
        await getDatabaseManager().initialize();

        await getDistributorManager().initialize();

        var distributor = getDistributorManager().getDistributorFromCache(
          user.distributor
        );
        q = query(
          collection(Firebase.firestore, "users"),
          or(
            where("store_distributors", "array-contains", user.distributor),
            where("distributor", "==", user.distributor),
            where(
              "manager_databases",
              "array-contains-any",
              distributor.databases
            )
          )
        );
      }

      this.listener = new SnapshotListener<User>(User.fromFirestore, q!);
      await this.listener.ensureInit();
    },
    getUsers() {
      return this.listener?.items!;
    },
    async performLogin(currentUser: any) {
      let userData = await this.getUser(currentUser!.uid);

      let state = getState();

      let navState = getNavigationState();

      await ModuleManager.loadModules();

      var result = await SecureatServerApi.connect(currentUser);

      state.connectedToApi = result != null && result.status == "ok";

      state.userData = userData;
      state.user = currentUser;

      if (state.customStoreId != null) {
        var storeManager = getStoreManager();

        await storeManager.initialize();

        state.customStore = await storeManager.getStore(state.customStoreId);
      }

      if (state.initialPage != null) {
        navState.navigate(state.initialPage, true);
      } else {
        if (userData.new_password_required) {
          navState.navigate("changePassword", true);
        } else {
          navState.navigate("home", true);
        }
      }
    },
    async signup(
      name: string,
      surname: string,
      email: string,
      password: string,
      phone: string
    ) {
      let state = getState();

      state.newAccount = true;

      var result = await SecureatServerApi.signup(
        name,
        surname,
        email,
        password,
        phone
      );

      console.log(result);

      return result;
    },

    async createUser(
      name: string,
      surname: string,
      email: string,
      password: string,
      phone: string,
      distributorId: string | null,
      databaseId: string | null,
      storeId: string | null,
      role: string
    ) {
      var result = await SecureatServerApi.createUser(
        name,
        surname,
        email,
        password,
        phone,
        distributorId,
        databaseId,
        storeId,
        role
      );

      return result;
    },

    async getUser(id: string): Promise<User> {
      const docRef = doc(Firebase.firestore, "users", id);

      const snapshot = await getDoc(docRef);

      let user: User = User.fromFirestore(snapshot);

      return user;
    },

    async getUserByEmail(email: string) {
      const ownerQuery = query(
        collection(Firebase.firestore, "users"),
        where("email", "==", email)
      );

      let snapshot = await getDocs(ownerQuery);

      let users = snapshot.docs.map((x) => User.fromFirestore(x));

      if (users.length == 0) {
        return null;
      } else {
        return users[0];
      }
    },
    async getDatabaseManagers(database: Database) {
      let managers: User[] = [];

      for (let manager of database.managers) {
        let doc = await getDoc(manager);

        managers.push(User.fromFirestore(doc));
      }

      return managers;
    },
    async getStoreOwners(store: Store) {
      let q: Query | null = null;

      let user = getState().userData;

      if (user.role == Role.Manager) {
        q = query(
          collection(Firebase.firestore, "users"),
          where("store_databases", "array-contains-any", user.manager_databases)
        );
      } else if (user.role == Role.Distributor) {
        q = query(
          collection(Firebase.firestore, "users"),
          where("store_distributors", "array-contains", user.distributor)
        );
      } else {
        q = query(
          collection(Firebase.firestore, "users"),
          where("stores", "array-contains", store.ref)
        );
      }

      let snapshot = await getDocs(q);

      let owners: User[] = [];

      snapshot.forEach((doc) => {
        let user = User.fromFirestore(doc);
        owners.push(user);
      });

      return owners.filter(
        (x) => x.stores != null && x.stores.some((x) => x.id == store.ref.id)
      );
    },
    async getDistributorContacts(distributor: Distributor): Promise<User[]> {
      const reference = doc(
        Firebase.firestore,
        "distributors",
        distributor.ref!.id
      );

      const q = query(
        collection(Firebase.firestore, "users"),
        where("distributor", "==", reference)
      );
      let snapshot = await getDocs(q);

      return snapshot.docs.map((x) => User.fromFirestore(x));
    },
  },
});
