import { DocumentReference } from "firebase/firestore";
import TaskResult from "./TaskResult";
import Element from "@/model/Elements/Element";
import PictureResult from "./PictureResult";
import { ElementTemperatureStateEnum } from "@/components/home/ElementTemperatureStateEnum";

export default class TemperatureResult extends TaskResult {
  public override isValid(): boolean {
    return this.isTemperatureRangeValid();
  }
  temperature: number | null;
  element: Element | null;
  firstTemperature: number | null;
  comment: string | null;
  pictures: PictureResult[];
  resultTimestamp: Date | null;
  control_temperature: number | null;

  constructor(
    details: string,
    status: string,
    timestamp: Date | null,
    element: Element | null,
    temperature: number | null,
    firstTemperature: number | null,
    comment: string | null,
    pictures: PictureResult[],
    resultTimestamp: Date | null,
    control_temperature: number | null
  ) {
    super(details, status, timestamp);
    this.element = element;
    this.temperature = temperature;
    this.firstTemperature = firstTemperature;
    this.comment = comment;
    this.pictures = pictures;
    this.resultTimestamp = resultTimestamp;
    this.control_temperature = control_temperature;
  }

  public isTemperatureRangeValid() {
    if (this.temperature != null) {
      if (
        this.temperature < this.element?.getMinimumTemperature()! ||
        this.temperature > this.element?.getMaximumTemperature()!
      ) {
        return false;
      }
    }

    if (this.control_temperature != null) {
      if (
        this.control_temperature < this.element?.getMinimumTemperature()! ||
        this.control_temperature > this.element?.getMaximumTemperature()!
      ) {
        return false;
      }
    }

    return true;
  }
  public getElementTemperatureStateEnum(): ElementTemperatureStateEnum {
    return this.isTemperatureRangeValid()
      ? ElementTemperatureStateEnum.InRange
      : ElementTemperatureStateEnum.OutOfRange;
  }
}
