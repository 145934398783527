import {
  DocumentSnapshot,
  DocumentReference,
  doc,
  Firestore,
  collection,
  documentId,
} from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import * as Lang from "@/i18n/lang";
import DatabaseLabel from "./DatabaseLabel";
import DatabaseElement from "../Elements/DatabaseElement";
import Label, { LabelType } from "./Label";
import Store from "../Store";
import * as Firebase from "@/firebase/Firebase";

export default class StoreLabel extends Label {
  origin: DocumentReference | null = null;
  originValue: DatabaseLabel | null = null;

  constructor(
    ref: DocumentReference,
    active: boolean | null = null,
    cumulate_expiration_duration: boolean | null = null,
    defrosting_duration: number | null = null,
    element: DocumentReference | null = null,
    expiration_duration: number | null = null,
    expiration_text: string | null = null,
    name: string | null = null,
    type: LabelType | null = null,
    index: number | null = null,
    origin: DocumentReference | null = null,
    show_expiration_hour: boolean | null = true,
    show_expiration_weekday: boolean | null = true
  ) {
    super(
      ref,
      active,
      cumulate_expiration_duration,
      defrosting_duration,
      element,
      expiration_duration,
      expiration_text,
      name,
      type,
      index,
      show_expiration_hour,
      show_expiration_weekday
    );

    this.origin = origin;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): StoreLabel {
    const data = snapshot.data()!;

    let result = new StoreLabel(
      snapshot.ref,
      data.active,
      data.cumulate_expiration_duration,
      data.defrosting_duration,
      data.element,
      data.expiration_duration,
      data.expiration_text,
      data.name,
      data.type,
      data.index == undefined ? null : data.index,
      data.origin,
      data.show_expiration_hour == undefined ? true : data.show_expiration_hour,
      data.show_expiration_weekday == undefined
        ? true
        : data.show_expiration_weekday
    );

    return result;
  }

  public override getActive() {
    return this.active != null
      ? this.active
      : this.originValue != null
      ? this.originValue.getActive()
      : this.active;
  }
  public override getCumulateExpirationDuration(labels_lock: boolean) {
    if (labels_lock) {
      return this.originValue != null
        ? this.originValue.cumulate_expiration_duration
        : this.cumulate_expiration_duration;
    } else {
      return this.cumulate_expiration_duration != null
        ? this.cumulate_expiration_duration
        : this.originValue != null
        ? this.originValue.cumulate_expiration_duration
        : this.cumulate_expiration_duration;
    }
  }
  public override getDefrostingDuration(labels_lock: boolean) {
    if (labels_lock) {
      return this.originValue != null
        ? this.originValue.defrosting_duration
        : this.defrosting_duration;
    } else {
      return this.defrosting_duration != null
        ? this.defrosting_duration
        : this.originValue != null
        ? this.originValue.defrosting_duration
        : this.defrosting_duration;
    }
  }
  public override getExpirationText(labels_lock: boolean) {
    if (labels_lock) {
      return this.originValue != null
        ? this.originValue.expiration_text
        : this.expiration_text;
    } else {
      return this.expiration_text != null
        ? this.expiration_text
        : this.originValue != null
        ? this.originValue.expiration_text
        : this.expiration_text;
    }
  }
  public override getType(labels_lock: boolean) {
    if (labels_lock) {
      return this.originValue != null ? this.originValue.type : this.type;
    } else {
      return this.type != null
        ? this.type
        : this.originValue != null
        ? this.originValue.type
        : this.type;
    }
  }
  public override getExpirationDuration(labels_lock: boolean): number | null {
    if (labels_lock) {
      return this.originValue != null
        ? this.originValue.expiration_duration
        : this.expiration_duration;
    } else {
      return this.expiration_duration != null
        ? this.expiration_duration
        : this.originValue != null
        ? this.originValue.expiration_duration
        : this.expiration_duration;
    }
  }
  public override getName(labels_lock: boolean) {
    if (labels_lock) {
      return this.originValue != null ? this.originValue.name : this.name;
    } else {
      return this.name != null
        ? this.name
        : this.originValue != null
        ? this.originValue.name
        : this.name;
    }
  }
  public override getShowExpirationHour(labels_lock: boolean): boolean {
    if (labels_lock) {
      return this.originValue != null
        ? this.originValue.show_expiration_hour
        : this.show_expiration_hour;
    } else {
      return this.show_expiration_hour != null
        ? this.show_expiration_hour
        : this.originValue != null
        ? this.originValue.show_expiration_hour
        : this.show_expiration_hour;
    }
  }
  public override getShowExpirationWeekday(labels_lock: boolean): boolean {
    if (labels_lock) {
      return this.originValue != null
        ? this.originValue.show_expiration_weekday
        : this.show_expiration_weekday;
    } else {
      return this.show_expiration_weekday != null
        ? this.show_expiration_weekday
        : this.originValue != null
        ? this.originValue.show_expiration_weekday
        : this.show_expiration_weekday;
    }
  }

  public toFirestore() {
    return {
      active: this.active,
      cumulate_expiration_duration: this.cumulate_expiration_duration,
      defrosting_duration: this.defrosting_duration,
      element: this.element,
      expiration_duration: this.expiration_duration,
      expiration_text: this.expiration_text,
      name: this.name,
      type: this.type,
      index: this.index,
      origin: this.origin,
      show_expiration_hour: this.show_expiration_hour,
      show_expiration_weekday: this.show_expiration_weekday,
    };
  }
  public setOriginValue(originValue: DatabaseLabel) {
    this.originValue = originValue;
  }

  public static fromOrigin(originValue: DatabaseLabel, store: Store) {
    let result = new StoreLabel(
      doc(collection(Firebase.firestore, store.ref.path, "/labels/")),
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      originValue.index,
      originValue.ref
    );
    result.setOriginValue(originValue);
    return result;
  }

  public toJson(): Record<string, any> {
    const baseJson = super.toJson();

    return {
      ...baseJson,
      origin: this.origin ? this.origin.path : null,
    };
  }

  public duplicate(labels_lock: boolean) {
    return new StoreLabel(
      doc(collection(Firebase.firestore, this.ref.parent.path)),
      this.getActive(),
      this.getCumulateExpirationDuration(labels_lock),
      this.getDefrostingDuration(labels_lock),
      this.element,
      this.getExpirationDuration(labels_lock),
      this.getExpirationText(labels_lock),
      this.getName(labels_lock),
      this.getType(labels_lock),
      this.index,
      null,
      this.getShowExpirationHour(labels_lock),
      this.getShowExpirationWeekday(labels_lock)
    );
  }

  public static fromJson(json: Record<string, any>, db: Firestore): StoreLabel {
    const elementRef: DocumentReference = doc(db, json.element);
    let originRef: DocumentReference | null = null;
    if (json.origin !== null) {
      originRef = doc(db, json.origin);
    }
    throw new Error();
    /*   return new StoreLabel(
      null,
      json.active,
      json.cumulate_expiration_duration,
      json.defrosting_duration,
      elementRef,
      json.expiration_duration,
      json.expiration_text,
      json.name,
      json.type,
      originRef
    ); */
  }
}
