import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "text-white",
  style: {"font-weight":"500"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HalfCircleSpinner = _resolveComponent("HalfCircleSpinner")!
  const _component_SeSnackbar = _resolveComponent("SeSnackbar")!

  return (_openBlock(), _createBlock(_component_SeSnackbar, null, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1),
      _createVNode(_component_HalfCircleSpinner, { style: {"color":"white","width":"30px","height":"30px"} })
    ]),
    _: 1
  }))
}