
import { PropType, defineComponent } from "vue";
import * as Lang from "@/i18n/lang";
import Communication from "@/model/Communication/Communication";
import { getCommunicationState } from "@/pinia/communications/CommunicationState";
import Quiz, { QuizQuestion } from "@/model/Communication/Quiz";
import AnswerList from "@/components/communication/AnswerList.vue";
export default defineComponent({
  data() {
    return {
      Lang: Lang,
      state: getCommunicationState(),
    };
  },
  components: {
    AnswerList,
  },
  setup() { },
  props: {
    communication: {
      type: Communication,
      required: true,
    },
  },
  methods: {
    openRemoveQuestionModal(question: QuizQuestion) {
      var refs = <any>this.$refs;
      refs.removeQuestionModal.open(question);
    },

    removeQuestion() {
      var refs = <any>this.$refs;
      var question = refs.removeQuestionModal.context;

      let index = this.communication.quiz!.questions.indexOf(question)!;
      this.communication.quiz!.questions.splice(index, 1);

      if (this.communication.quiz!.questions!.length > 0) {
        this.state.selectedQuestion = this.communication.quiz!.questions[0]!;
      } else {
        this.state.selectedQuestion = null;
      }
      refs.removeQuestionModal.close();
    },
    abortRemoveQuestion() {
      var refs = <any>this.$refs;
      refs.removeQuestionModal.close();
    },
    openQuestionModal(question: QuizQuestion | null = null) {
      var refs = <any>this.$refs;

      if (question != null) {
        refs.questionName.setValue(question.question);
      } else {
        refs.questionName.setValue("");
      }
      refs.questionModal.open(question);
    },
    addOrModifyQuestion() {
      var refs = <any>this.$refs;
      var content = refs.questionName.getValue();

      if (refs.questionModal.context != null) {
        refs.questionModal.context.question = content;
      } else {
        let question = new QuizQuestion(content, []);
        this.communication.quiz!.questions.push(question);
        this.state.selectedQuestion = question;
      }

      refs.questionModal.close();
    },
  },
});
