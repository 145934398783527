
import { defineComponent } from "@vue/runtime-core";
import * as DOMUtils from "@/utils/DOMUtils";

export default defineComponent({
    props: {},
    expose: ["open", "close"],
    mounted() {

        // Listen for clicks on the parent to toggle open/close
        this.$parent.$el.addEventListener("click", this.onParentClick);

        this.$parent.$el.style.overflow = "visible"
    },
    unmounted() {

    },
    methods: {

        onClick() {
            if (!this.isOpen()) {
                this.open();
            } else {
                console.log("closing from parent")
                this.close();
            }
        },
        getMenu() {
            return this.$el.querySelector('.pmenu');
        },
        onDocumentClick(event: MouseEvent) {

            const target = event.target as HTMLElement;

            // Close the menu if click is outside both the menu and the parent
            if (
                DOMUtils.isDescendantOf(target, this.$el) ||
                target == this.$el ||
                DOMUtils.isDescendantOf(target, this.$parent.$el) ||
                target == this.$parent.$el
            ) {
                return;
            }
            else {
                console.log("closing from global hook.");
                this.close();
            }
        },

        open() {
            document.addEventListener("mousedown", this.onDocumentClick);
            this.getMenu().classList.add("pmenu-open");
            this.ensureMenuSize();
        },
        close() {
            this.getMenu().classList.remove("pmenu-open");
            document.removeEventListener("mousedown", this.onDocumentClick);
        },
        isOpen() {
            return this.getMenu().classList.contains("pmenu-open");
        },
        ensureMenuSize() {
            // e.g. if you want to automatically resize based on viewport space:
            const menu = this.getMenu() as HTMLElement;
            const menuRect = menu.getBoundingClientRect();

            if (this.autoResizeMenu) {
                // How much space is left below?
                const spaceBelow = Math.round(window.innerHeight - menuRect.top - 50);
                menu.style.maxHeight = spaceBelow + "px";
            }
        },
    },
});
