import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f0785896"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flex justify-center align-center"
}
const _hoisted_2 = {
  key: 1,
  style: {"text-overflow":"ellipsis","max-width":"100%","overflow":"hidden"},
  class: "flex col grow g0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    class: "dropdown-item"
  }, [
    (_ctx.$slots.heading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "heading", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.displayTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.getTitle()), 1),
          _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots.tailing)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "flex align-center justify-center",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          _renderSlot(_ctx.$slots, "tailing", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}