
import { collection, doc, DocumentReference } from "firebase/firestore";
import { defineComponent, nextTick } from "vue";
import * as Lang from "@/i18n/lang";
import * as DateUtils from "@/utils/DateUtils";
import { Role } from "@/model/User";
import { getState } from "@/pinia/AppState";
import { getStoreDatabaseState } from "@/pinia/databases/StoreDatabaseState";
import Employee from "@/model/Employees/Employee";
import SecureatIllustration from "@/model/utils/SecureatIllustration";
import * as Storage from "@/firebase/Storage";
import * as Snackbars from "@/utils/Snackbars";
import * as Firebase from "@/firebase/Firebase";
import { getEmployeeManager } from "@/firebase/EmployeeManager";
import SeLoadingIndicator from "../global/SeLoadingIndicator.vue";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import Device from "@/model/Device";
import * as DeviceManager from "@/firebase/DeviceManager";
import SeFadeButton from "../global/SeFadeButton.vue";
import ToggleSwitch from "../global/ToggleSwitch.vue";
import ReadonlyField from "../global/ReadonlyField.vue";

export default defineComponent({
  expose: ["onTabOpen"],
  data() {
    return {
      Role: Role,
      Lang: Lang,
      loading: false,
      DateUtils: DateUtils,
      devices: [] as Device[],
      currentDevice: null as Device | null,
    };
  },

  setup() {
    var state = getStoreDatabaseState();

    return {
      role: getState().userData?.role,
      state: state,
    };
  },
  methods: {
    removeDevice(device: Device) {
      this.devices = this.devices.filter((d) => d.ref.id !== device.ref.id);

      this.state.store.devices = this.state.store.devices.filter(
        (d) => d.id !== device.ref.id
      );
    },
    async saveClick() {
      this.$refs.saveBtn.toggleLoading(true);

      for (let device of this.devices) {
        await device.set();
      }

      await this.state.store.set();
      this.$refs.saveBtn.toggleLoading(false);
      Snackbars.display("Modifications enregistrées !");
    },
    closeDeviceModal() {
      this.currentDevice.slow_internet_mode = this.$refs.slowInternet.toggled();
      this.currentDevice.dark_mode = this.$refs.darkMode.toggled();
      this.currentDevice.audio_task_reminders =
        this.$refs.audioTaskReminders.toggled();
      this.currentDevice.rotation_lock = this.$refs.rotationLock.toggled();

      this.currentDevice.prevent_screen_lock =
        this.$refs.preventScreenLock.toggled();
      this.$refs.deviceModal.close();
    },
    onDeviceClick(device: Device) {
      this.currentDevice = device;
      this.$refs.deviceModal.open();

      nextTick(() => {
        this.$refs.darkMode.toggle(device.dark_mode);
        this.$refs.slowInternet.toggle(device.slow_internet_mode);
        this.$refs.audioTaskReminders.toggle(device.audio_task_reminders);
        this.$refs.rotationLock.toggle(device.rotation_lock);
        this.$refs.preventScreenLock.toggle(device.prevent_screen_lock);
      });
    },
    async onTabOpen() {
      this.loading = true;

      console.log(this.state.store.devices);

      if (this.state.store.devices.length > 0) {
        this.devices = await DeviceManager.getDevices(this.state.store);
      } else {
        this.devices = [];
      }

      this.loading = false;
    },
  },
});
