
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";
import Task from "@/model/Task";
import * as DateUtils from "@/utils/DateUtils";
import * as TaskManager from "@/firebase/TaskManager";

export default defineComponent({
  props: {
    task: {
      type: Task,
      required: true,
    },
  },
  setup(props) {
    var status = TaskManager.getTaskStatus(props.task);

    return {
      status: status,
    };
  },
  methods: {
    getColor(): string {
      switch (this.status) {
        case "postponed":
          return "#f3841c";
        case "done":
          return "#1dd000";
        case "late":
          return "#eb387a";
        case "on-time":
          return "#454eff";
        case "done-late":
          return "#1dd000";
      }

      console.log("Unknown status: " + this.status);
      return "unknown";
    },
    getStatusText(): string {
      if (this.status == "late") {
        var completionDate = TaskManager.getExpectedCompletionDate(this.task);

        if (completionDate.getDate() != new Date().getDate()) {
          return Lang.getI18N("non-finalized");
        }
        return Lang.getI18N(this.status);
      }
      return Lang.getI18N(this.status);
    },
  },
});
