import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-415da9c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex grow" }
const _hoisted_2 = { class: "bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_SeImage = _resolveComponent("SeImage")!
  const _component_Value = _resolveComponent("Value")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Row = _resolveComponent("Row")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    title: _ctx.Lang.getI18N('employee-schedule-today')
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.seeAllSchedules && _ctx.seeAllSchedules(...args))),
        class: "link-text"
      }, _toDisplayString(_ctx.Lang.getI18N("see-all")), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Table, null, {
          columns: _withCtx(() => [
            _createVNode(_component_Column, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("name")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("role")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("clock-in-and-out")), 1)
              ]),
              _: 1
            })
          ]),
          rows: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.homeState.employees.filter((x) => x.active), (employee) => {
              return (_openBlock(), _createBlock(_component_Row, {
                key: employee.ref.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Value, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_SeImage, {
                        maxWidth: 30,
                        src: 
                    employee.illustration_url
                      ? employee.illustration_url.get()
                      : '/ico/account.svg'
                  
                      }, null, 8, ["src"]),
                      _createElementVNode("span", _hoisted_2, _toDisplayString(employee.name) + " " + _toDisplayString(employee.surname), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_Value, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Badge, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.homeState.getRoleName(employee.role)), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_Value, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.homeState.getPunchCardEntries(
                    employee,
                    new Date()
                  ), (entry) => {
                        return (_openBlock(), _createBlock(_component_Badge, {
                          color: entry.getColor(),
                          onClick: ($event: any) => (_ctx.openSelfie(entry))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(entry.getLabel()), 1)
                          ]),
                          _: 2
                        }, 1032, ["color", "onClick"]))
                      }), 256))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}