
import { defineComponent } from "vue";

export default defineComponent({
  props: [
    "items",
    "selectionChanged",
    "allSelected",
    "singleSelect",
    "titleFunc",
  ],

  expose: [
    "unselectItem",
    "selectItem",
    "getSelectedItems",
    "getSelectedIndexes",
    "selectIndex",
    "reset",
  ],

  data() {
    return {
      selectedItems: [] as string[],
    };
  },
  mounted() {
    if (this.allSelected) {
      for (let i = 0; i < this.items.length; i++) {
        this.selectIndex(i);
      }
    }
  },
  methods: {
    getSelectedItems() {
      return this.selectedItems;
    },
    getSelectedIndexes(): number[] {
      return this.selectedItems.map((x) => this.items.indexOf(x));
    },
    getItemClass(item: string) {
      if (this.items.indexOf(item) == 0) {
        return "btn text border-left";
      } else if (this.items.indexOf(item) == this.items.length - 1) {
        return "btn text border-right";
      }
      return "btn text";
    },

    reset() {
      this.selectedItems = [];
    },

    unselectItem(item: string) {
      const index = this.selectedItems.indexOf(item);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }

      let childs = this.$el.childNodes[0].childNodes;

      for (let i = 0; i < childs.length; i++) {
        let child = childs[i];

        if (child.textContent == this.titleFunc(item)) {
          child.childNodes[0].style.backgroundColor = "rgba(69, 78, 255, 0.1)";
          child.childNodes[0].style.color = "#454eff";
        }
      }
    },
    selectIndex(index: number) {
      this.selectItem(this.items[index]);
    },
    selectItem(item: string) {
      this.selectedItems.push(item);

      let childs = this.$el.childNodes[0].childNodes;

      for (let i = 0; i < childs.length; i++) {
        let child = childs[i];

        if (child.textContent == this.titleFunc(item)) {
          child.childNodes[0].style.background = "#454eff";
          child.childNodes[0].style.color = "white";
        }
      }
    },
    onBtnClick(item: string) {
      if (this.singleSelect) {
        for (let item of this.selectedItems) {
          this.unselectItem(item);
        }

        this.selectItem(item);
      } else {
        if (this.selectedItems.includes(item)) {
          this.unselectItem(item);
        } else {
          this.selectItem(item);
        }
      }

      if (this.selectionChanged != undefined && this.selectionChanged != null)
        this.selectionChanged(
          this.selectedItems.map((x) => this.items.indexOf(x)),
          this.selectedItems
        );
    },
  },
});
