import {
  DocumentSnapshot,
  DocumentReference,
  Timestamp,
} from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import Attachment from "../Filesystem/Attachment";
import * as DateUtils from "@/utils/DateUtils";

export default class TrainingResult extends SecureatSnapshot {
  store: DocumentReference;
  employee: DocumentReference;
  skipped_count: number;
  time_spent: number | null;
  completed_timestamp: Date | null;
  store_database: DocumentReference | null = null;
  store_distributor: DocumentReference | null = null;

  constructor(
    ref: DocumentReference,
    store: DocumentReference,
    employee: DocumentReference,
    skipped_count: number,
    time_spent: number | null,
    completed_timestamp: Date | null,
    store_database: DocumentReference | null = null,
    store_distributor: DocumentReference | null = null
  ) {
    super(ref);
    this.store = store;
    this.employee = employee;
    this.skipped_count = skipped_count;
    this.time_spent = time_spent;
    this.completed_timestamp = completed_timestamp;
    this.store_database = store_database;
    this.store_distributor = store_distributor;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): TrainingResult {
    const data = snapshot.data()!;

    let result = new TrainingResult(
      snapshot.ref,
      data.store,
      data.employee,
      data.skipped_count,
      data.time_spent,
      data.completed_timestamp == undefined
        ? null
        : DateUtils.getDateFromSecondsLocal(data.completed_timestamp.seconds),
      data.store_database != undefined ? data.store_database : null,
      data.store_distributor != undefined ? data.store_distributor : null
    );
    return result;
  }

  public toFirestore() {
    return {
      store: this.store,
      employee: this.employee,
      skipped_count: this.skipped_count,
      time_spent: this.time_spent,
      completed_timestamp: this.completed_timestamp,
      store_database: this.store_database,
      store_distributor: this.store_distributor,
    };
  }
}
