
import { collection, doc, DocumentReference } from "firebase/firestore";
import { defineComponent, nextTick } from "vue";
import * as Lang from "@/i18n/lang";
import { Role } from "@/model/User";
import { getState } from "@/pinia/AppState";
import { getStoreDatabaseState } from "@/pinia/databases/StoreDatabaseState";
import Employee from "@/model/Employees/Employee";
import SecureatIllustration from "@/model/utils/SecureatIllustration";
import * as Storage from "@/firebase/Storage";
import * as Snackbars from "@/utils/Snackbars";
import * as Firebase from "@/firebase/Firebase";
import { getEmployeeManager } from "@/firebase/EmployeeManager";
import SeLoadingIndicator from "../global/SeLoadingIndicator.vue";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import SeModal from "../global/SeModal.vue";
import * as Modals from "@/utils/Modals";

export default defineComponent({
  components: {
    SeModal,
  },
  expose: ["onTabOpen"],
  data() {
    return {
      Role: Role,
      Lang: Lang,
      loading: false,
      currentEmployee: null as Employee | null,
      passwordDigits: ["", "", "", ""],
      patternPoints: [] as number[],
      isUsingPassword: true,
    };
  },

  setup() {
    var state = getStoreDatabaseState();

    return {
      toggleRight: getState().customStoreId == null,
      role: getState().userData?.role,
      state: state,
    };
  },
  methods: {
    async onTabOpen() {
      this.loading = true;

      this.state.roles = await getDatabaseManager().getEmployeeRoles(
        this.state.store.database
      );

      this.state.employees = await getEmployeeManager().getEmployees(
        getState().userData,
        this.state.store
      );

      this.loading = false;
    },
    addEmployee() {
      this.state.employees.push(
        new Employee(
          doc(collection(Firebase.firestore, "employees")),
          "Nouvel ",
          "employé",
          true,
          null,
          null,
          [],
          [this.state.store.ref],
          [this.state.store.database],
          null,
          null,
          true,
          null
        )
      );
    },
    async saveEmployees() {
      var refs = <any>this.$refs;

      if (
        this.state.roles.length > 0 &&
        this.state.employees.some((x) => x.active && x.role == null)
      ) {
        Modals.open(
          "Veuillez selectionner un role d'employé pour chaque employé."
        );
        return;
      }

      for (let employee of this.state.employees) {
        await employee.set();
      }

      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
    async onEmployeeIllustrationChange(
      event: ProgressEvent,
      file: File,
      employee: Employee
    ) {
      var path = await Storage.uploadEmployeeIllustration(employee, file);

      employee.illustration_url = new SecureatIllustration(
        await Storage.getDownloadUrl(path)
      );

      await employee.set();
    },
    openAuthModal(employee: Employee) {
      this.currentEmployee = employee;
      this.isUsingPassword =
        employee.using_password === undefined ? true : employee.using_password;

      // Reset form values
      this.passwordDigits = ["", "", "", ""];
      this.patternPoints = [];

      // Set initial values if they exist
      if (employee.password) {
        const digits = employee.password.split("");
        digits.forEach((digit, index) => {
          if (index < 4) this.passwordDigits[index] = digit;
        });
      }

      if (employee.pattern) {
        this.patternPoints = [...employee.pattern];
      }

      // Open modal
      const authModal = this.$refs.authModal as any;

      authModal.open();

      var tab = this.$refs.authTabComponent as any;

      nextTick(() => {
        tab.selectFirstIndex();
      });
    },
    closeAuthModal() {
      const authModal = this.$refs.authModal as any;
      authModal.close();
    },
    onAuthTabChange(tabIndex: number) {
      this.isUsingPassword = tabIndex === 0;
    },
    onPinDigitInput(index: number) {
      // Ensure only numbers
      this.passwordDigits[index] = this.passwordDigits[index].replace(
        /[^0-9]/g,
        ""
      );

      // Auto-focus next input if value is entered
      if (this.passwordDigits[index] && index < 3) {
        const nextInput = document.querySelectorAll(".pin-digit")[
          index + 1
        ] as HTMLInputElement;
        if (nextInput) nextInput.focus();
      }
    },
    handlePinKeydown(event: KeyboardEvent, index: number) {
      // Handle backspace - move to previous input
      if (
        event.key === "Backspace" &&
        !this.passwordDigits[index] &&
        index > 0
      ) {
        const prevInput = document.querySelectorAll(".pin-digit")[
          index - 1
        ] as HTMLInputElement;
        if (prevInput) prevInput.focus();
      }
    },
    togglePatternPoint(pointIndex: number) {
      const pointPosition = this.patternPoints.indexOf(pointIndex);

      // If point already exists in pattern, remove it and all points after it
      if (pointPosition !== -1) {
        this.patternPoints = this.patternPoints.slice(0, pointPosition);
      } else {
        // Add point to pattern
        this.patternPoints.push(pointIndex);
      }
    },
    clearPattern() {
      this.patternPoints = [];
    },
    saveAuthSettings() {
      if (!this.currentEmployee) return;

      if (this.isUsingPassword) {
        // Save password
        const password = this.passwordDigits.join("");
        if (password.length === 4) {
          this.currentEmployee.password = password;
          this.currentEmployee.using_password = true;
        } else {
          Snackbars.display(Lang.getI18N("invalid-password"));
          return;
        }
      } else {
        console.log("Save pattern");
        // Save pattern
        if (this.patternPoints.length >= 3) {
          this.currentEmployee.pattern = [...this.patternPoints];
          this.currentEmployee.using_password = false;
        } else {
          Snackbars.display(Lang.getI18N("pattern-too-short"));
          return;
        }
      }

      this.closeAuthModal();
    },
  },
});
