
import { getHomeState } from "@/pinia/home/HomeState";
import { defineComponent } from "vue";
import * as DateUtils from "@/utils/DateUtils";
import SeProgressBar from "../global/SeProgressBar.vue";
import { getCommunicationStatsState } from "@/pinia/communications/CommunicationStatsState";
import { getState } from "@/pinia/NavigationState";
import Communication from "@/model/Communication/Communication";
import Employee from "@/model/Employees/Employee";
import CommunicationResult from "@/model/Communication/CommunicationResult";
import Training from "@/model/Trainings/Training";
import { getTrainingStatsState } from "@/pinia/trainings/TrainingStatsState";
import TrainingResult from "@/model/Trainings/TrainingResult";
import * as Lang from "@/i18n/lang";
import { getAuditManager } from "@/firebase/AuditManager";
import { Role } from "@/model/User";
import Audit from "@/model/Auditing/Audit";
import Card from "../newGlobal/Card.vue";
import { getStoreManager } from "@/firebase/StoreManager";

export default defineComponent({
    name: "Audits",
    data() {
        return {
            Lang: Lang,
            DateUtils: DateUtils,
        };
    },
    async setup() {
        var state = getHomeState();


        var audits = [] as Audit[];

        if (state.selectedDatabase != null) {
            await getAuditManager().ensureAuditListener(state.selectedDatabase.ref.id);

            audits = await getAuditManager().getAudits(Role.Admin, state.selectedDatabase.ref.id);

            audits = audits.filter(x => x.published)


            let totalStores = await getStoreManager().getStoresFromDatabase(state.selectedDatabase);


            for (let audit of audits) {
                var results = await getAuditManager().getResults(audit);


                let effectiveStores = [];

                for (let result of results) {
                    if (!effectiveStores.some(x => x == result.store.id)) {
                        effectiveStores.push(result.store.id);
                    }
                }

                audit.viewRatio = effectiveStores.length / totalStores.length;

            }
        }

        return {
            homeState: getHomeState(),
            audits: audits,
        };
    },
    methods: {

    },
});
