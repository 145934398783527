
import * as Lang from "@/i18n/lang";
import SearchBar from "@/components/global/SearchBar.vue";
import { defineComponent } from "vue";
import { getDatabaseState } from "@/pinia/databases/DatabaseState";
import SeFadeButton from "@/components/global/SeFadeButton.vue";
import SeEditableText from "@/components/global/SeEditableText.vue";
import SeCheckboxGroup from "@/components/global/SeCheckboxGroup.vue";
import EmployeeRole from "@/model/Employees/EmployeeRole";
import WideCard from "@/components/global/WideCard.vue";
import { ExtensionEnum } from "@/model/ExtensionEnum";
import { ModuleEnum } from "@/model/ModuleEnum";
import SeButton from "@/components/global/SeButton.vue";
import * as Snackbars from "@/utils/Snackbars";
import * as Firebase from "@/firebase/Firebase";
import { collection, doc } from "firebase/firestore";
import * as ModuleManger from "@/firebase/ModuleManager";

export default defineComponent({
  components: {},

  data() {
    return {
      Lang: Lang,
      selectedRole: null as EmployeeRole | null,
    };
  },

  async mounted() {
    if (this.state.employee_roles.length > 0)
      this.selectRole(this.state.employee_roles[0]);
  },
  methods: {
    updateCurrentRole() {
      if (this.selectedRole) {
        var refs = <any>this.$refs;

        var selected = refs.extensionsSelect.getSelectedItems();

        var permissions = new Map<string, any>();

        for (let feature of selected) {
          permissions.set(feature, null);
        }

        this.selectedRole.features_permissions = permissions;
      }
    },
    addRole() {
      var role = new EmployeeRole(
        doc(
          collection(
            Firebase.firestore,
            "databases/" + this.state.database!.ref.id + "/roles"
          )
        ),
        "Nouveau role",
        true,
        new Map<string, any>()
      );
      this.state.employee_roles.push(role);
      this.selectRole(role);
    },
    selectRole(role: EmployeeRole) {
      this.updateCurrentRole();

      this.selectedRole = role;

      var refs = <any>this.$refs;

      if (this.selectedRole.features_permissions) {
        var selected = [];

        var keys = Array.from(this.selectedRole.features_permissions.keys());

        for (let feature of this.getExtensionEnums()) {
          if (keys.includes(feature.toString())) {
            selected.push(feature);
          }
        }

        refs.extensionsSelect.setSelectedItems(selected);
      } else {
        refs.extensionsSelect.unselectAll();
      }
    },
    getLabelFromI18N(module: String) {
      return Lang.getI18N(module.toString());
    },
    getExtensionEnums() {
      var results: string[] = [];

      var extensions = Object.values(ExtensionEnum).map((x) => x.toString());

      var modules = Object.values(ModuleEnum).map((x) => x.toString());

      for (let extension of extensions) {
        if (!this.state.database.hidden_extensions.includes(extension)) {
          results.push(extension);
        }
      }

      for (let module of modules) {
        var moduleObj = ModuleManger.getModuleFromEnum(<ModuleEnum>module);

        if (
          this.state.database.hidden_modules.some(
            (x) => x.id == moduleObj.ref.id
          )
        ) {
          continue;
        }
        results.push(module);
      }
      return results;
    },

    async save() {
      this.updateCurrentRole();
      var refs = <any>this.$refs;

      for (let role of this.state.employee_roles) {
        await role.set(false);
      }
      Snackbars.display("Roles sauvegardés");
    },
  },

  async setup() {
    let state = getDatabaseState();
    return {
      state: state,
    };
  },
});
