import {
  DocumentSnapshot,
  DocumentReference,
  Timestamp,
} from "firebase/firestore";
import SecureatSnapshot from "./SecureatSnapshot";

export default class Device extends SecureatSnapshot {
  public device_id: string;
  public device_model: string;
  public creation_timestamp: Date;
  public last_connexion: Date;
  public calendar_types_order: string[];
  public dark_mode: boolean;
  public linked_user: DocumentReference;
  public app_version: string;
  public slow_internet_mode: boolean;
  public audio_task_reminders: boolean;

  public show_unscheduled_tasks: boolean;

  public os_version: string;
  public rotation_lock: boolean;
  public name: string;

  public prevent_screen_lock: boolean = true;

  constructor(
    ref: DocumentReference,
    device_id: string,
    device_model: string,
    creation_timestamp,
    last_connexion: Date,
    calendar_types_order: string[],
    dark_mode: boolean,
    linked_user: DocumentReference,
    app_version: string,
    slow_internet_mode: boolean,
    audio_task_reminders: boolean,
    show_unscheduled_tasks: boolean,
    os_version: string,
    rotation_lock: boolean,
    name: string,
    prevent_screen_lock: boolean = true
  ) {
    super(ref);
    this.device_id = device_id;
    this.device_model = device_model;
    this.creation_timestamp = creation_timestamp;
    this.last_connexion = last_connexion;
    this.calendar_types_order = calendar_types_order;
    this.dark_mode = dark_mode;
    this.linked_user = linked_user;
    this.app_version = app_version;
    this.slow_internet_mode = slow_internet_mode;
    this.audio_task_reminders = audio_task_reminders;
    this.show_unscheduled_tasks = show_unscheduled_tasks;
    this.os_version = os_version;
    this.rotation_lock = rotation_lock;
    this.name = name;
    this.prevent_screen_lock = prevent_screen_lock;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): Device {
    const data = snapshot.data()!;

    let result = new Device(
      snapshot.ref,
      data.device_id,
      data.device_model,
      data.creation_timestamp.toDate(),
      data.last_connexion.toDate(),
      data.calendar_types_order,
      data.dark_mode,
      data.linked_user,
      data.app_version,
      data.slow_internet_mode,
      data.audio_task_reminders,
      data.show_unscheduled_tasks,
      data.os_version,
      data.rotation_lock,
      data.name,
      data.prevent_screen_lock
    );

    return result;
  }

  public toFirestore() {
    var result = {
      device_id: this.device_id,
      device_model: this.device_model,
      creation_timestamp: this.creation_timestamp,
      last_connexion: this.last_connexion,
      calendar_types_order: this.calendar_types_order,
      dark_mode: this.dark_mode,
      linked_user: this.linked_user,
      app_version: this.app_version,
      slow_internet_mode: this.slow_internet_mode,
      audio_task_reminders: this.audio_task_reminders,
      show_unscheduled_tasks: this.show_unscheduled_tasks,
      os_version: this.os_version,
      rotation_lock: this.rotation_lock,
      name: this.name,
      prevent_screen_lock: this.prevent_screen_lock,
    };

    return result;
  }
}
