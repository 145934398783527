import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-27cbf750"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "stateLabel",
  class: "text-muted",
  style: {"display":"none","color":"#d90000"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeInput = _resolveComponent("SeInput")!
  const _component_SeButton = _resolveComponent("SeButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, " Adresse email ou mot de passe incorrect. ", 512),
    _createVNode(_component_SeInput, {
      ref: "email",
      placeholder: "Email",
      style: {"width":"130%"},
      value: ""
    }, null, 512),
    _createVNode(_component_SeInput, {
      ref: "password",
      type: "password",
      placeholder: _ctx.Lang.getI18N('password'),
      style: {"width":"130%"},
      value: ""
    }, null, 8, ["placeholder"]),
    _createVNode(_component_SeButton, {
      onPress: _ctx.login,
      ref: "loginBtn",
      style: {"width":"130%","height":"50px"}
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("login")), 1)
      ]),
      _: 1
    }, 8, ["onPress"]),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openRegister && _ctx.openRegister(...args))),
      class: "link-text"
    }, _toDisplayString(_ctx.Lang.getI18N("register")), 1),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openLostPass && _ctx.openLostPass(...args))),
      class: "link-text"
    }, _toDisplayString(_ctx.Lang.getI18N("lost-password")), 1)
  ], 64))
}