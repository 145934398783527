
import { defineComponent } from "vue";
import Badge from "../newGlobal/Badge.vue";
import { getHomeState } from "@/pinia/home/HomeState";
import * as Modals from "@/utils/Modals";
import Employee from "@/model/Employees/Employee";
import PunchCardEntry from "@/model/Employees/PunchCardEntry";
import EmployeePunchCard from "@/model/Employees/EmployeePunchCard";
import PunchCardPair from "@/model/Employees/PunchCardPair";
import { getState } from "@/pinia/NavigationState";
import * as Lang from "@/i18n/lang";

export default defineComponent({
  setup() {
    return {
      homeState: getHomeState(),
    };
  },
  data() {
    return {
      Lang: Lang,
    };
  },
  methods: {
    seeAllSchedules() {
      getState().navigate("punchCards");
    },
    openSelfie(entry: PunchCardEntry) {
      if (entry.selfie_url == null) {
        return;
      }
      Modals.openGallery([entry.selfie_url], () => {});
    },
  },
});
