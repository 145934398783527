
import * as Snackbars from "@/utils/Snackbars";
import * as ModuleManager from "@/firebase/ModuleManager";
import * as Lang from "@/i18n/lang";
import NavHeader from "../components/nav/NavPanel.vue";
import ElementList from "@/components/databases/ElementList.vue";
import TaskConfigurationList from "@/components/databases/TaskConfigurationList.vue";
import SeCheckBox from "@/components/global/SeCheckBox.vue";
import Element from "@/model/Elements/Element";
import TaskConfiguration from "@/model/Tasks/TaskConfiguration";
import SearchBar from "../components/global/SearchBar.vue";
import SeTab from "../components/global/SeTab.vue";
import { getElementManager } from "@/firebase/ElementManager";
import { getStoreManager } from "@/firebase/StoreManager";
import { getStoreDatabaseState } from "@/pinia/databases/StoreDatabaseState";
import { getStoreTaskConfigurationState } from "@/pinia/tasks/StoreTaskConfigurationState";
import { getStoreElementState } from "@/pinia/elements/StoreElementState";
import User, { Role } from "@/model/User";
import { ModuleEnum } from "@/model/ModuleEnum";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import { getState } from "@/pinia/NavigationState";
import { getState as getAppState } from "@/pinia/AppState";
import { defineComponent, nextTick } from "vue";
import { getAllergensState } from "@/pinia/allergens/AllergensState";
import { getBulkStoreElementState } from "@/pinia/elements/BulkStoreElementState";
import { getBulkStoreTaskState } from "@/pinia/tasks/BulkStoreTaskState";
import EmployeeRole from "@/model/Employees/EmployeeRole";
import {
  collection,
  doc,
  DocumentReference,
  Firestore,
} from "firebase/firestore";
import Employee from "@/model/Employees/Employee";
import * as Firebase from "@/firebase/Firebase";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import { ExtensionEnum } from "@/model/ExtensionEnum";
import { getStoreLabels } from "@/firebase/LabelsManager";
import SeIllustration from "@/components/global/SeIllustration.vue";
import * as Storage from "@/firebase/Storage";
import SecureatIllustration from "@/model/utils/SecureatIllustration";
import { getUserManager } from "@/firebase/UserManager";
import * as MailManager from "@/firebase/MailManager";
import * as Modals from "@/utils/Modals";
import StoreTabGeneral from "@/components/stores/StoreTabGeneral.vue";
import StoreTabKoovea from "@/components/stores/StoreTabKoovea.vue";
import StoreTabEmployee from "@/components/stores/StoreTabEmployee.vue";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";
import SeLoadingIndicator from "@/components/global/SeLoadingIndicator.vue";
import StoreTabDevices from "@/components/stores/StoreTabDevices.vue";

export default defineComponent({
  components: {
    ElementList,
    TaskConfigurationList,
    SeCheckBox,
    StoreTabGeneral,
    StoreTabKoovea,
    StoreTabEmployee,
    StoreTabDevices,
  },

  data() {
    let user = getAppState().userData;

    return {
      ExtensionEnum: ExtensionEnum,
      user: user,
      Role: Role,
      tasksLoaded: false,
      elementsLoaded: false,
    };
  },
  methods: {
    onNewClicked() {
      if (this.readonly) {
        Snackbars.display(Lang.getI18N("no-permission"));
        return;
      }
      this.state.tabIndex == 1
        ? this.openElementCreationPage()
        : this.openTaskCreationPage();
    },
    openBulk() {
      if (this.readonly) {
        Snackbars.display(Lang.getI18N("no-permission"));
        return;
      }
      if (this.state.tabIndex == 1) {
        getBulkStoreElementState().set(this.state.store);
        getNavigationState().navigate("bulkStoreElement");
      } else {
        getBulkStoreTaskState().set(this.state.store!);
        getNavigationState().navigate("bulkStoreTask");
      }
    },

    getSectionEnums() {
      return [];
    },

    onScroll(evt: any) {
      this.state.scrollY = evt.target.scrollTop;
    },
    onElementSearchChange(value: string) {
      this.state.elementSearch = value;
    },
    onTaskSearchChange(value: string) {
      this.state.taskSearch = value;
    },
    onTabChange(selectedIndex: number) {
      this.state.tabIndex = selectedIndex;
      nextTick(() => {
        this.setDefaultTabState();
      });
    },
    openElementCreationPage() {
      getStoreElementState().set(null, this.state.store!);
      getState().navigate("storeElement");
    },
    openTaskCreationPage() {
      getStoreTaskConfigurationState().set(null, this.state.store!);
      getState().navigate("storeTaskConf");
    },

    openExportModal() {
      var refs = <any>this.$refs;
      refs.exportModal.open();
    },

    openFileInput() {
      let refs = <any>this.$refs;
      refs.fileInput.click();
    },
    async loadTasks() {
      if (this.tasksLoaded) {
        return;
      }
      let taskConfigurationManager = getTaskConfigurationManager();

      await taskConfigurationManager.ensureDatabaseTaskConfigurationListener(
        this.state.store?.database?.id!
      );

      await taskConfigurationManager.ensureStoreTaskConfigurationListener(
        this.state.store?.ref?.id!
      );
      this.state.tasks = taskConfigurationManager.getTaskConfigurations(
        this.state.store!
      );
      this.tasksLoaded = true;
    },
    async loadElements() {
      if (this.elementsLoaded) {
        return;
      }
      let elementManager = getElementManager();
      await elementManager.ensureDatabaseElementListener(
        this.state.store?.database?.id!
      );

      await elementManager.ensureStoreElementListener(
        this.state.store?.ref?.id!
      );
      this.state.elements = elementManager.getElements(this.state.store!);

      elementManager.getDatabaseElementListener(
        this.state.store?.database?.id!
      ).updated = () => {
        this.state.elements = elementManager.getElements(this.state.store!);
      };

      elementManager.getStoreElementListener(
        this.state.store?.ref?.id!
      ).updated = () => {
        this.state.elements = elementManager.getElements(this.state.store!);
      };

      this.elementsLoaded = true;
    },

    async setDefaultTabState() {
      var refs = <any>this.$refs;
      if (this.state.tabIndex == 0) {
        await refs.generalTab.onTabOpen();
      } else if (this.state.tabIndex == 1) {
        await this.loadElements();
        refs.search.setSearch(this.state.elementSearch);
      } else if (this.state.tabIndex == 2) {
        await this.loadTasks();
        refs.search.setSearch(this.state.taskSearch);
      } else if (this.state.tabIndex == 3) {
        refs.employeeTab.onTabOpen();
      } else if (this.state.tabIndex == 4) {
        await refs.kooveaTab.onTabOpen();
      } else if (this.state.tabIndex == 5) {
        await refs.deviceTab.onTabOpen();
      }
    },
  },
  async mounted() {
    var index = this.state.tabIndex;

    let tab = <any>this.$refs.tab;

    var refs = <any>this.$refs;

    refs.mainLayout.scrollTop = this.state.scrollY;

    await nextTick(async () => {
      tab.changeTabIndex(index);
      await this.setDefaultTabState();
    });
  },

  async setup() {
    var state = getStoreDatabaseState();

    var readonly =
      getAppState().userData.role == Role.Manager &&
      state.store.manager_owned == false;

    await state.setup();

    return {
      readonly: readonly,
      state: state,
      role: getAppState().userData?.role,
      Lang: Lang,

      elementManager: getElementManager(),
    };
  },
});
