
import * as Storage from "@/firebase/Storage";
import * as Snackbars from "@/utils/Snackbars";
import * as Modals from "@/utils/Modals";
import * as MailManager from "../firebase/MailManager";
import * as Lang from "@/i18n/lang";
import SeInput from "../components/global/SeInput.vue";
import ToggleSwitch from "../components/global/ToggleSwitch.vue";
import SeButton from "../components/global/SeButton.vue";
import User from "../model/User";
import dateFormat from "dateformat";
import Database from "@/model/Database";
import ProbeList from "../components/probes/ProbeList.vue";
import Invitation, { InvitationType } from "@/model/Invitation";
import { getState } from "../pinia/NavigationState";
import { getStoreState } from "@/pinia/stores/StoreState";
import { getState as getAppState } from "@/pinia/AppState";
import { getStoreDatabaseState } from "@/pinia/databases/StoreDatabaseState";
import { getAccountState } from "@/pinia/accounts/AccountState";
import { defineComponent } from "vue";
import { Role } from "@/model/User";
import { ModuleEnum } from "@/model/ModuleEnum";
import { getUserManager } from "../firebase/UserManager";
import { getStoreManager } from "../firebase/StoreManager";
import { getDatabaseManager } from "../firebase/DatabaseManager";
import { collection, getDoc } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import { doc, DocumentReference } from "firebase/firestore";

import * as SecureatServerApi from "@/utils/SecureatServerApi";
import ReadonlyField from "@/components/global/ReadonlyField.vue";
import { KooveaSticker } from "@/model/Koovea/KooveaSticker";
import ActivationHistory from "@/model/ActivationHistory";
import SecureatIllustration from "@/model/utils/SecureatIllustration";

export default defineComponent({
  async mounted() { },
  async setup() {
    let appState = getAppState();

    let state = getStoreState();

    await state.setup();

    return {
      toggleRight: appState.customStoreId == null,
      role: getAppState().userData?.role,
      state: state,
    };
  },
  methods: {
    async onIllustrationChange(event: ProgressEvent, file: File) {
      await Storage.uploadStoreIllustration(this.state.selectedStore!, file);

      this.state.selectedStore!.illustration_url = new SecureatIllustration(
        await Storage.getStoreIllustrationUrl(this.state.selectedStore!)
      );

      await this.state.selectedStore!.set();
    },

    async selectDatabase() {
      let refs = <any>this.$refs;

      let database = this.state.selectedDatabase;

      if (database !== null && database.distributor != null) {
        this.state.selectedStore!.database = database.ref!;
        this.state.selectedStore!.distributor = database.distributor!;
        if (
          !database.stores.some(
            (x) => x.id == this.state.selectedStore!.ref?.id
          )
        ) {
          database.stores.push(this.state.selectedStore!.ref!);
          await database.set();
        }
      } else {
        this.dbError = Lang.getI18N("invalid-database");
        return;
      }

      this.state.currentDatabase = database;

      await this.state.selectedStore!.set();

      refs.databasesModal.close();
    },

    async updateStore() {
      let refs = <any>this.$refs;


      if (
        this.state.initialStore.secureat_licence !=
        this.state.selectedStore.secureat_licence
      ) {
        this.state.initialStore.licence_activation_history.push(
          new ActivationHistory(
            new Date(),
            this.state.selectedStore.secureat_licence
          )
        );
      }
      if (this.state.selectedSticker != null) {
        this.state.selectedStore.koovea_sticker = this.state.selectedSticker.id;
      }
      await this.state.selectedStore!.set();


      Snackbars.display(Lang.getI18N("saved-successfully"));
    },

    manageTasks() {
      getStoreDatabaseState().set(this.state.selectedStore!);
      getState().navigate("storeDatabase");
    },
    openAccount(account: User) {
      getAccountState().selectedAccount = account;
      getState().navigate("account");
    },

    openDatabasesModal() {
      let refs = <any>this.$refs;
      refs.databasesModal.open();
    },
  },

  data() {
    return {
      dbError: "",
      dateFormat: dateFormat,
      Lang: Lang,
      ModuleEnum: ModuleEnum,
      Role: Role,
    };
  },

  components: { SeButton, ProbeList },
});
