import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4130e2ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex grow justify-between align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeModal = _resolveComponent("SeModal")!
  const _component_SeFadeButton = _resolveComponent("SeFadeButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SeModal, {
      title: "Edit",
      ref: "editModal"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("input", {
          class: "se-input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event))
        }, null, 512), [
          [_vModelText, _ctx.localValue]
        ])
      ]),
      _: 1
    }, 512),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(_ctx.modelValue), 1),
      _createVNode(_component_SeFadeButton, {
        onClick: _ctx.openModal,
        icon: "/ico/edit-blue.svg"
      }, null, 8, ["onClick"])
    ])
  ], 64))
}