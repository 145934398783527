import {
  collection,
  query,
  where,
  doc,
  getDocs,
  getDoc,
  addDoc,
  documentId,
  updateDoc,
  DocumentReference,
  orderBy,
} from "firebase/firestore";

import { getState } from "../pinia/AppState";
import Module from "@/model/Module";
import { ModuleEnum } from "@/model/ModuleEnum";
import Store from "@/model/Store";
import * as Firebase from "@/firebase/Firebase";
import Device from "@/model/Device";

export async function getDevices(store: Store) {
  const q = query(
    collection(Firebase.firestore, "devices"),
    where(documentId(), "in", store.devices)
  );

  var snapshot = await getDocs(q);

  return snapshot.docs.map((x) => Device.fromFirestore(x));
}
