
import Store from "@/model/Store";
import { getHomeState } from "@/pinia/home/HomeState";
import { defineComponent, nextTick } from "@vue/runtime-core";
import { HalfCircleSpinner } from "epic-spinners";
import SearchBar from "../global/SearchBar.vue";
import { getState } from "@/pinia/AppState";
import { Role } from "@/model/User";
import Database from "@/model/Database";

export default defineComponent({
    components: { HalfCircleSpinner },
    props: {
        icon: String,
        valueChange: Function,
    },
    methods: {

        onStoreClick(store: Store) {

            this.selectedStore = store;
            this.selectedDatabase = null;

            var refs = <any>this.$refs;
            refs.popup.close();

            if (this.valueChange != null) {
                this.valueChange(this.selectedStore, this.selectedDatabase);
            }


        },
        onDatabaseClick(database: Database) {
            this.selectedDatabase = database;
            this.selectedStore = null;

            if (this.valueChange != null) {
                this.valueChange(this.selectedStore, this.selectedDatabase);
            }

            var refs = <any>this.$refs;
            refs.popup.close();
        },
        onTabChange(index: number) {
            this.tabIndex = index;
        },
    },

    data() {
        return {
            selectedDatabase: null as Database | null,
            selectedStore: null as Store | null,
            Role: Role,
            role: getState().userData.role,
            tabIndex: 0,
            homeState: getHomeState()
        }
    },
})
