
import { Component, defineComponent } from "@vue/runtime-core";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
} from "firebase/auth";
import LoginView from "../../views/LoginView.vue";
import { ref } from "vue";
import { getState } from "@/pinia/AppState";

import * as Lang from "../../i18n/lang";
import { getState as getAppState } from "../../pinia/AppState";
import { getState as getNavigationState } from "../../pinia/NavigationState";
import { getUserManager } from "../../firebase/UserManager";
import User from "../../model/User";
import { FirebaseError } from "firebase/app";
import { LoginViewType } from "@/views/LoginViewType";

export default defineComponent({
  data() {
    return { Lang: Lang };
  },
  unmounted() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  mounted() {
    document.addEventListener("keydown", this.onKeyDown);
    let userEmail = getState().initialEmail;
    if (userEmail !== null) {
      (this.$refs.email as any).setValue(userEmail);
    }
  },
  async setup() {
    let appState = getAppState();
    let navState = getNavigationState();

    if (appState.token == null) {
      return;
    }

    const auth = getAuth();

    try {
      let userCredential = await signInWithCustomToken(auth, appState.token);
      // Signed in
      const user = userCredential.user;

      let state = getAppState();

      let userData = await getUserManager().getUser(auth.currentUser!.uid);

      state.userData = userData;

      appState.token = null;

      if (state.initialPage != null) {
        getNavigationState().navigate(state.initialPage, true);
      } else {
        getNavigationState().navigate("home");
      }
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorMessage);
    }
  },

  methods: {
    async onKeyDown(event: any) {
      if (event.key === "Enter") {
        await this.login();
      }
    },
    openLostPass() {
      (this.$parent as any).switchView(LoginViewType.LostPassword);
    },
    openRegister() {
      console.log("test");
      (this.$parent as any).switchView(LoginViewType.Registration);
    },
    async login() {
      let loginBtn: any = this.$refs.loginBtn;

      let email = (this.$refs.email as any).getValue().toLowerCase();

      let password = (this.$refs.password as any).getValue();

      let stateLabel: HTMLElement = this.$refs.stateLabel as HTMLElement;

      const auth = getAuth();

      try {
        let userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        // Signed in
        const user = userCredential.user;

        let state = getAppState();

        let userData = await getUserManager().getUser(auth.currentUser!.uid);

        state.userData = userData;
      } catch (error: any) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
        stateLabel.style.display = "";
      }
    },
  },
});
