
import { defineComponent } from "vue";
import NavHeader from "../../components/nav/NavHeader.vue";
import * as Lang from "@/i18n/lang";
import { getAuditResultsState } from "@/pinia/audits/AuditResultsState";
import { Result } from "@/model/Auditing/Result";
import { getPDFDownloadLink } from "@/components/auditing/pdfGeneratorNew";
import SeExpendable from "@/components/global/SeExpendable.vue";
import AuditResultList from "@/components/auditing/results/AuditResultList.vue";
import { Parser } from "@json2csv/plainjs";
import dateFormat from "dateformat";
import * as Modals from "@/utils/Modals";
import * as SecureatServerApi from "@/utils/SecureatServerApi";

export default defineComponent({
  components: {
    NavHeader,
    AuditResultList,
    SeExpendable,
  },
  async setup() {
    var state = getAuditResultsState();

    return {
      state: state,
    };
  },
  data() {
    return { Lang: Lang, isLoading: false };
  },
  computed: {
    isOneResultSelected(): boolean {
      return this.state.selectedResults!.length === 1;
    },
    isOneOrMoreResultSelected(): boolean {
      return this.state.selectedResults!.length > 0;
    },
    getResultLabel(): (result: Result) => string {
      return (result: Result) => {
        if (result.store) {
          let store = this.state.stores!.find((store) => {
            return store.ref!.id === result.store!.id;
          });

          if (store) {
            return `${result.getEndDateFormat()} - ${store.name}`;
          }
        }
        return result.getEndDateFormat();
      };
    },
  },
  mounted() {},
  methods: {
    async setSelectedResults(selectedIndexes: []) {
      let newSelectedResults: Result[] = [];
      selectedIndexes.map((selectedIndex) => {
        newSelectedResults.push(this.state.results[selectedIndex]);
      });
      this.state.selectedResults = newSelectedResults;
      this.state.set(this.state.audit!, newSelectedResults);
    },
    async openPDF() {
      var refs = <any>this.$refs;

      if (this.state.selectedResults.length == 1) {
        var result = this.state.selectedResults[0];
        console.log(result);
        if (result.pdf_url != null) {
          window.open(result.pdf_url, "_blank");
          return;
        }
      }

      this.isLoading = true;

      await Promise.all(
        this.state.selectedResults?.map(async (selectedResult) => {
          await SecureatServerApi.generateAuditResultPDF(
            selectedResult?.ref!.path
          );
        })
      );
      this.isLoading = false;
    },

    async openCSV() {
      if (!this.state.audit) return;
      let jsonData: Record<string, any>[] = [];
      this.state.selectedResults?.forEach((selectedResult, index) => {
        let jsonResult: Record<string, any> = {};
        jsonResult["Date"] = selectedResult.getEndDateFormat(true);
        const questionCounts: Record<string, number> = {};

        this.state.audit?.getSections().forEach((section) => {
          section.getQuestions().forEach((question) => {
            let questionKey = question.text;
            if (questionCounts[question.text] !== undefined) {
              questionKey += `_${questionCounts[question.text]}`;
            }
            questionCounts[question.text] =
              (questionCounts[question.text] || 0) + 1;

            jsonResult[questionKey] = question.answers
              ? question.answers[index]
                ? question.answers[index].toPDF()
                : ""
              : "";
          });
        });

        jsonData.push(jsonResult);
      });

      try {
        const opts = {
          delimiter: ",",
          header: true,
        };
        const parser = new Parser(opts);
        const csv = parser.parse(jsonData);
        let currentDate = dateFormat(new Date(), "dd/mm/yyyy_HH:MM");
        let fileName = `${this.state.audit.name}-${currentDate}.csv`;
        this.downloadCSVFile(csv, fileName);
      } catch (err) {
        console.error("Error parsing CSV:", err);
      }
    },
    downloadCSVFile(csvData: string, fileName: string) {
      const blob = new Blob([csvData], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    },
  },
});
