
import { defineComponent } from "vue";
import NavHeader from "../components/nav/NavHeader.vue";
import * as Lang from "@/i18n/lang";
import { getState } from "@/pinia/AppState";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import * as Modals from "@/utils/Modals";
import * as Snackbars from "@/utils/Snackbars";
import { updatePassword } from "firebase/auth";
import { FirebaseError } from "firebase/app";

export default defineComponent({
  components: {},
  mounted() { },
  methods: {
    async apply() {
      var refs = <any>this.$refs;

      var state = getState();

      if (this.password.trim() == "" || this.passwordConf.trim() == "") {
        Snackbars.display(
          "Veuillez renseigner une valeur pour le mot de passe."
        );
        return;
      }
      if (this.password != this.passwordConf) {
        Snackbars.display("Les deux mot de passes ne correspondent pas.");
        return;
      }


      try {
        await updatePassword(getState().user, this.password);
      } catch (e: any) {
        console.log(e);
        switch (e.code) {
          case "auth/weak-password":
            this.error =
              "Votre mot de passe est trop faible. Veuillez choisir un mot de passe plus sécurisé.";
            break;

          case "auth/requires-recent-login":
            this.error =
              "Il y a trop de temps entre votre connexion et votre tentative de changement de mot de passe. Vous allez être deconnecté.";

            setTimeout(this.state.logout, 5000);
            break;

          default:
            this.error = e.code;
            break;
        }
        return;
      }

      state.userData!.new_password_required = false;

      await state.userData?.set();

      getNavigationState().navigate("home", true);
    },
  },
  async setup() { },
  data() {
    return {
      state: getState(),
      error: "",
      password: "",
      passwordConf: "",
      Lang: Lang,
    };
  },
});
