
import { defineComponent, toRef, nextTick } from "vue";
import SeFadeButton from "./SeFadeButton.vue";
import SeModal from "./SeModal.vue";

export default defineComponent({
  expose: ["getValue", "setValue", "switch"],
  emits: ["update:modelValue"],
  name: "SeInput",
  data() {
    return {
      editable: null as Boolean | null,
      inputWidth: 100, // Add a property to store the input width
    };
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },

  props: {
    modelValue: {},
    value: {},
    edit: Boolean,
    auto: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    openModal() {
      this.$refs.editModal.open();
    },

  },
  mounted() {
  },
});
