export const I18N_DE = new Map([
  ["edit-auth", "Authentifizierung bearbeiten"],
  ["employee-authentication", "Mitarbeiter-Authentifizierung"],
  ["set-auth", "Auth festlegen"],
  ["password-set", "Passwort festgelegt"],
  ["pattern", "Muster"],
  ["pattern-set", "Muster festgelegt"],
  ["enter-4-digit-pin", "Geben Sie einen 4-stelligen PIN-Code ein"],
  ["draw-pattern", "Zeichnen Sie ein Muster (mindestens 3 Punkte)"],
  ["clear", "Löschen"],
  ["cancel", "Abbrechen"],
  ["save", "Speichern"],
  ["invalid-password", "Ungültiges Passwort. Bitte geben Sie eine 4-stellige PIN ein."],
  ["pattern-too-short", "Muster zu kurz. Bitte wählen Sie mindestens 3 Punkte aus."],
  ["auth-updated", "Authentifizierung erfolgreich aktualisiert."],
  ["register", "Benötigen Sie ein Konto? Treten Sie mit uns in Kontakt"],
  ["login", "Anmelden"],
  ["password", "Passwort"],
  ["home", "Startseite"],
  ["distributors", "Verteiler"],
  ["databases", "Datenbanken"],
  ["database", "Datenbank"],
  ["tasks", "Aufgaben"],
  ["stores", "Geschäfte"],
  ["probes", "Sonden"],
  ["search", "Suche"],
  ["new", "Neu"],
  ["today", "Heute"],
  ["yesterday", "Gestern"],
  ["this-week", "Diese Woche"],
  ["done", "Erledigt"],
  ["on-time", "Pünktlich"],
  ["postponed", "Verschoben"],
  ["late", "Verspätet"],
  ["address", "Adresse"],
  ["postal-code", "Postleitzahl"],
  ["city", "Stadt"],
  ["country", "Land"],
  ["open", "Öffnen"],
  ["schedule", "Zeitplan"],
  ["yearly", "Jährlich"],
  ["monthly", "Monatlich"],
  ["weekly", "Wöchentlich"],
  ["daily", "Täglich"],
  ["apply", "Anwenden"],
  ["name", "Name"],
  ["surname", "Nachname"],
  ["owners", "Besitzer"],
  ["add", "Hinzufügen"],
  ["modify", "Ändern"],
  ["rooms", "Räume"],
  ["equipement", "Ausstattung"],
  ["all", "Alle"],
  ["working", "Arbeitend"],
  ["not-working", "Nicht arbeitend"],
  ["phone-number", "Telefonnummer"],
  ["customer", "Kunde"],
  ["distributor", "Verteiler"],
  ["admin", "Administrator"],
  ["manager", "Manager"],
  ["managers", "Manager"],
  ["accounts", "Konten"],
  ["billing", "Abrechnung"],
  ["support", "Support"],
  ["add", "Hinzufügen"],
  ["apply", "Anwenden"],
  ["temperatures", "Temperaturen"],
  ["labels", "Etiketten"],
  ["traceability", "Rückverfolgbarkeit"],
  ["edit", "Bearbeiten"],
  ["task-schedule", "Aufgabenplan"],
  ["task-configuration", "Aufgabenkonfiguration"],
  ["new-room", "Neuer Raum"],
  ["active", "Aktiv"],
  ["inactive", "Inaktiv"],
  ["start", "Start"],
  ["end", "Ende"],
  ["enter-the-email-of-the-user", "Geben Sie die E-Mail des Benutzers ein"],
  ["add-owner", "Besitzer hinzufügen"],
  ["manage", "Verwalten"],
  ["assigned-to", "Zugewiesen an"],
  ["activate-module", "Modul aktivieren"],
  [
    "content-not-available",
    "Dieser Inhalt ist im Moment nicht verfügbar! Kommen Sie später zurück",
  ],
  ["logout", "Ausloggen"],
  ["requires-picture", "Benötigt ein Bild"],
  ["requires-manager", "Benötigt einen Manager"],
  ["oils", "Öle"],
  ["elements", "Elemente"],
  ["new-element", "Neues Element"],
  ["new-task", "Neue Aufgabe"],
  ["field-locked", "Dieses Feld ist gesperrt"],
  ["field-unlocked", "Dieses Feld ist entsperrt"],
  ["minimum-temperature", "Mindesttemperatur"],
  ["maximum-temperature", "Maximaltemperatur"],
  ["probe-url", "Sonden-URL"],
  ["picture", "Bild erforderlich"],
  ["delivery_item", "Warenlieferung"],
  ["maximum-polarity", "Maximale Polarität"],
  ["polarity", "Polarität"],
  ["action-name", "Aktionsname"],
  ["every", "Jede(r)"],
  ["day(s)", "Tag(e)"],
  ["week(s)", "Woche(n)"],
  ["month(s)", "Monat(e)"],
  ["year(s)", "Jahr(e)"],
  ["monday", "Montag"],
  ["tuesday", "Dienstag"],
  ["wednesday", "Mittwoch"],
  ["thursday", "Donnerstag"],
  ["friday", "Freitag"],
  ["saturday", "Samstag"],
  ["sunday", "Sonntag"],
  ["first", "Erste(r)"],
  ["second", "Zweite(r)"],
  ["third", "Dritte(r)"],
  ["fourth", "Vierte(r)"],
  ["on-day", "Am Tag"],
  ["on-the", "Am"],
  ["defrosting", "Abtauen"],
  ["expiration", "Ablauf"],
  ["simple-name", "Name"],
  ["expected-date", "Erwartetes Datum"],
  ["submission-date", "Einreichungsdatum"],
  ["employee", "Mitarbeiter"],
  ["cleaned", "Gereinigt"],
  ["oil-changed", "Öl gewechselt"],
  ["task-done", "Aufgabe erledigt"],
  ["task-late", "Aufgabe verspätet"],
  ["task-future", "Zukünftige Aufgabe"],
  ["no-task-today", "Keine Aufgaben heute"],
  ["store-database", "Geschäftsdatenbank"],
  ["task-action-required", "Bitte fügen Sie mindestens eine Aufgabe hinzu"],
  ["password-conf", "Passwortbestätigung"],
  ["countries", "Länder"],
  ["new-label", "Neues Etikett"],
  ["edit-label", "Etikett bearbeiten"],
  ["history", "Verlauf"],
  ["expend", "Erweitern"],
  ["reduce", "Verringern"],
  ["cumulative-expirations", "Kumulative Abläufe"],
  ["expiration-duration", "Dauer bis zum Ablauf"],
  ["defrosting-duration", "Dauer des Abtauens"],
  ["singleton-name", "Einzelname"],
  [
    "invalid-temperature-input",
    "Die maximale Temperatur für das Temperaturmodul muss größer oder gleich der minimalen Temperatur sein.",
  ],
  ["comment", "Kommentar"],
  ["temperature", "Temperatur"],
  ["refrigeration", "Kühlung"],
  ["documents", "Dokumente"],
  ["save", "Speichern"],
  ["secureat-licence", "Secureat-Lizenz"],
  ["no-elements", "Keine Elemente"],
  ["schedule-incoherent", "Inkohärente Zeitpläne"],
  ["hourless_expiration", "Stundenloses Ablaufen"],
  ["customer_expiration", "Kundenablauf"],
  ["download", "Herunterladen"],
  ["rename", "Umbenennen"],
  ["delete", "Löschen"],
  ["share", "Teilen"],
  ["subtasks", "Teilaufgaben"],
  ["close", "Schließen"],
  ["count", "Anzahl"],
  ["new-folder", "Neuer Ordner"],
  ["back", "Zurück"],
  [
    "unsaved-changes",
    "Die Seite enthält nicht gespeicherte Änderungen. Möchten Sie wirklich zurückgehen?",
  ],
  ["user-not-found", "Benutzer nicht gefunden"],
  ["form-invalid", "Bitte füllen Sie alle Felder aus."],
  ["required-field", "Bitte füllen Sie das Feld aus"],
  ["cancel", "Abbrechen"],
  ["yes", "Ja"],
  ["no", "Nein"],
  ["duplicate", "Duplikat"],
  ["freeform_expiration", "DLC (Freeform)"],
  ["auditing", "Überprüfung"],
  ["published", "Veröffentlicht"],
  ["structure", "Struktur"],
  ["new-section", "Neue Sektion"],
  ["new-question", "Neue Frage"],
  ["question", "Frage"],
  ["question.multiple_choice", "Multiple Choice"],
  ["question.text_input", "Texteingabe"],
  ["question.yes_or_no", "Einzelne Wahl"],
  ["question.file_upload", "Dokument hochladen"],
  ["question.rating", "Bewertung"],
  ["question.number", "Nummer"],
  ["question.date", "Datum"],
  ["options", "Optionen"],
  ["limit-characters", "Zeichenbegrenzung"],
  ["max-characters", "Maximale Anzahl von Zeichen"],
  ["score", "Punktzahl"],
  ["preview", "Vorschau"],
  ["flutter-embedding", "Flutter-Einbindung"],
  ["min-rating", "Minimale Bewertung"],
  ["max-rating", "Maximale Bewertung"],
  ["min-number", "Minimale Nummer"],
  ["max-number", "Maximale Nummer"],
  ["no-options", "Hier gibt es nichts einzustellen."],
  ["allowed-extensions", "Erlaubte Dateierweiterungen."],
  ["limit-current-date", "Datumsbegrenzung"],
  ["date.no_limit", "Keine Begrenzung"],
  ["date.only_future", "Nur Zukunft"],
  ["date.only_past", "Nur Vergangenheit"],
  ["results", "Ergebnisse"],
  ["general", "Allgemein"],
  ["time-target-temperature", "Zeit bis zur Zieltemperatur"],
  ["target-temperature", "Zieltemperatur"],
  ["postpone-date", "Verschiebungsdatum"],
  ["select-all", "Alle auswählen"],
  ["ask-domhygiene", "Keine Aufgabe für diesen Zeitraum gefunden."],
  ["lost-password", "Passwort vergessen? Klicken Sie hier"],
  [
    "login.recovery.help",
    "Bitte geben Sie die während der Kontoerstellung angegebene E-Mail-Adresse an.",
  ],
  ["login.recovery.send-mail", "Wiederherstellungs-E-Mail senden"],
  [
    "login.recovery.error",
    "Es konnte keine Wiederherstellungsnachricht gesendet werden. Bitte geben Sie eine gültige E-Mail-Adresse an.",
  ],
  [
    "login.recovery.success",
    "Eine Wiederherstellungsnachricht wurde an die angegebene E-Mail-Adresse gesendet.",
  ],
  ["multiple-selection", "Mehrfachauswahl"],
  ["choices", "Auswahlen"],
  ["new-choice", "Neue Auswahl"],
  ["choice-name", "Name der Auswahl"],
  ["section-name", "Name der Sektion"],
  ["delete-dialog", "Löschen bestätigen"],
  [
    "delete-confirm",
    "Warnung: Sie sind dabei, dieses Element zu löschen. Diese Aktion kann nicht rückgängig gemacht werden. Möchten Sie fortfahren?",
  ],
  ["total-score", "Gesamtpunktzahl"],
  ["questions-empty", "Es wurden noch keine Fragen hinzugefügt"],
  ["add-new-section", "Neue Sektion hinzufügen"],
  ["already-manager", "Dieses Konto verwaltet bereits diese Datenbank"],
  ["opening", "Öffnen"],
  ["temperature_control", "Temperaturkontrolle"],
  [
    "target-require-customer",
    "Die Rolle des Zielkontos muss Kunde sein und darf keine Geschäfte besitzen. Bitte ein neues Konto erstellen.",
  ],
  ["welcome-to-secureat", "Willkommen im Secureat-Dashboard"],
  ["download-pdf", "PDF herunterladen"],
  ["sections", "Sektionen"],
  ["questions", "Fragen"],
  ["reheating-temperature", "Wiederaufwärmtemperatur"],
  ["cooling-temperature", "Kühltemperatur"],
  ["cooling", "Kühlung"],
  ["reheating", "Wiederaufwärmen"],
  [
    "invalid-database",
    "Ungültige Datenbank. Die Datenbank hat keinen Verteiler.",
  ],
  ["preparations", "Vorbereitungen"],
  ["saved-successfully", "Erfolgreich gespeichert"],
  ["start-temperature", "Starttemperatur"],
  ["end-temperature", "Endtemperatur"],
  ["temperature-control-duration", "Dauer"],
  ["attachment", "Anhang"],
  ["period", "Periode"],
  ["add-condition", "Bedingung hinzufügen"],
  ["edit-condition", "Bedingung bearbeiten"],
  ["choose-question", "Bedingungsfrage auswählen"],
  ["question-condition", "Antwort auf die Frage für diese Bedingung auswählen"],
  ["create", "Erstellen"],
  ["no-parameters", "Dieses Modul hat keine Parameter."],
  ["import", "Importieren"],
  ["export", "Exportieren"],
  ["imported-successfully", "Daten erfolgreich importiert"],
  ["import-error", "Fehler beim Importieren aufgetreten"],
  ["user-already-owns-store", "Der Benutzer besitzt dieses Geschäft bereits"],
  [
    "unable-admin-assign-store",
    "Es ist nicht möglich, einem Administratorkonto ein Geschäft zuzuweisen",
  ],
  [
    "unable-manager-assign-store",
    "Es ist nicht möglich, einem Managerkonto ein Geschäft zuzuweisen",
  ],
  ["email.invitation.subject", "Einladung, Geschäftsinhaber zu werden"],
  [
    "email.invitation.body",
    "Sie wurden eingeladen, das Geschäft zu übernehmen",
  ],
  ["email.invitation.body.login", "Jetzt bei SecurEat anmelden"],
  ["email.invitation.body.register", "Erstellen Sie jetzt Ihr SecurEat-Konto"],
  ["email.invitation.snackbar.sent", "Einladung wurde gesendet an"],
  [
    "form.store.invalid.exists",
    "Ein Geschäft mit diesem Namen oder dieser Adresse existiert bereits.",
  ],
  ["rating-precision", "Präzision"],
  ["question-required", "Frage erforderlich"],
  ["warning-dialog", "Warnung"],
  [
    "audit-published-warning",
    "Achtung: Das Audit wurde bereits veröffentlicht. Seien Sie vorsichtig, da Änderungen am Audit möglicherweise bestehende Antworten beeinträchtigen können.",
  ],
  [
    "audit-publish-confirmation",
    "Sind Sie sicher, dass Sie das Audit veröffentlichen möchten?",
  ],
  ["publish", "Veröffentlichen"],
  ["duplicated-successfully", "Erfolgreich dupliziert."],
  ["store.select-database", "Datenbank auswählen"],
  ["lock", "Sperren"],
  ["unlock", "Freischalten"],
  [
    "user-store-limit",
    "Ein Benutzer darf nicht mehr als 30 Geschäfte besitzen.",
  ],
  ["answer", "Antwort"],
  ["score", "Punktzahl"],
  ["percentage", "Prozentsatz"],
  [
    "audit-no-results",
    "Für diese Überprüfung wurden noch keine Ergebnisse hinzugefügt.",
  ],
  ["open-pdf", "PDF öffnen"],
  ["open-csv", "CSV öffnen"],
  ["required", "Erforderlich"],
  [
    "select-results",
    "Bitte wählen Sie die Ergebnisse aus, die Sie sehen möchten.",
  ],
  ["email", "Email"],
  ["last", "Letzte"],
  ["january", "Januar"],
  ["february", "Februar"],
  ["march", "März"],
  ["april", "April"],
  ["may", "Mai"],
  ["june", "Juni"],
  ["july", "Juli"],
  ["august", "August"],
  ["september", "September"],
  ["october", "Oktober"],
  ["november", "November"],
  ["december", "Dezember"],
  ["of", "Von"],
  ["question-missing", "Frage nicht vorhanden"],
  ["answer-missing", "Keine Antwort wurde bereitgestellt"],
  ["range", "Bereich"],
  ["min", "Min"],
  ["max", "Max"],
  [
    "range-picker-invalid",
    "Der Mindestwert muss kleiner als der Höchstwert sein.",
  ],
  ["from", "Von"],
  ["to", "bis"],
  ["no-results", "Keine Ergebnisse"],
  ["true", "Wahr"],
  ["false", "Falsch"],
  ["not-taken", "Nicht genommen"],
  ["settings", "Einstellungen"],
  ["allergens", "Allergene"],
  ["select-allergens", "Allergene auswählen"],
  ["allergen.A", "Glutenhaltige Getreide"],
  ["allergen.B", "Krustentiere"],
  ["allergen.C", "Ei"],
  ["allergen.D", "Fisch"],
  ["allergen.E", "Erdnuss"],
  ["allergen.F", "Soja"],
  ["allergen.G", "Milch oder Laktose"],
  ["allergen.H", "Essbare Nüsse"],
  ["allergen.L", "Sellerie"],
  ["allergen.M", "Senf"],
  ["allergen.N", "Sesam"],
  ["allergen.O", "Sulfite"],
  ["allergen.P", "Lupinen"],
  ["allergen.R", "Weichtiere"],
]);
