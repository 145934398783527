import { h, render } from "vue";
import SeSnackbarText from "@/components/global/snackbars/SeSnackbarText.vue";
import SeSnackbarUpload from "@/components/global/snackbars/SeSnackbarUpload.vue";
import { random } from "lodash";
import UploadStrategy from "@/model/Uploads/UploadStategy";
import SeSnackbarProgress from "@/components/global/snackbars/SeSnackbarProgress.vue";

let snackbarCount = 1;

export function display(
  text: string,
  duration: number = 2500,
  type: "info" | "error" = "info"
) {
  const snackbarContainer = document.createElement("div");
  document.body.appendChild(snackbarContainer);

  const snackbarComponent = h(SeSnackbarText, { text: text });

  render(snackbarComponent, snackbarContainer);

  if (type == "error") {
    snackbarComponent.el.style.backgroundColor = "rgb(222, 81, 81)";
    snackbarComponent.el.style.boxShadow =
      "5px 5px 12px rgba(222, 81, 81, 0.4)";
  }

  setTimeout(() => {
    snackbarContainer.classList.add("fade-anim");

    setTimeout(() => {
      remove(snackbarContainer);
    }, 900);
  }, duration);

  snackbarCount++;
}

export function remove(snackbarContainer: HTMLDivElement) {
  document.body.removeChild(snackbarContainer);
  snackbarCount--;
}

export function progress(text: string): any {
  const snackbarContainer = document.createElement("div");
  document.body.appendChild(snackbarContainer);

  const snackbarComponent = h(SeSnackbarProgress, { text: text });

  render(snackbarComponent, snackbarContainer);

  snackbarCount++;

  return snackbarContainer;
}
export function uploader(strategy: UploadStrategy): any {
  const snackbarContainer = document.createElement("div");
  document.body.appendChild(snackbarContainer);

  const snackbarComponent = h(SeSnackbarUpload, {
    uploadStrategy: strategy,
  });

  render(snackbarComponent, snackbarContainer);

  snackbarCount++;

  return snackbarComponent;
}
