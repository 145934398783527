import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-58c4a836"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "audit-grid p12 grow" }
const _hoisted_2 = { class: "flex justify-between align-center" }
const _hoisted_3 = { class: "bold f12" }
const _hoisted_4 = { class: "flex align-center" }
const _hoisted_5 = { class: "text-muted f12" }
const _hoisted_6 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_SeProgressBar = _resolveComponent("SeProgressBar")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    title: _ctx.Lang.getI18N('audit')
  }, {
    header: _withCtx(() => []),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.audits, (audit) => {
          return (_openBlock(), _createBlock(_component_Card, null, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(audit.name), 1),
                _createVNode(_component_Badge, { color: "#85db7f" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Audit")
                  ]),
                  _: 1
                })
              ])
            ]),
            body: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_SeProgressBar, {
                  value: Math.round(audit.viewRatio * 100)
                }, null, 8, ["value"]),
                _createElementVNode("span", _hoisted_5, _toDisplayString(Math.round(audit.viewRatio * 100)) + " % effectué", 1)
              ]),
              _createElementVNode("span", _hoisted_6, " Publié le " + _toDisplayString(_ctx.DateUtils.formatDate((<any>
                            audit.publishing_date).toDate())), 1)
            ]),
            _: 2
          }, 1024))
        }), 256))
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}