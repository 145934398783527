import {
  DocumentSnapshot,
  DocumentReference,
  doc,
  collection,
} from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import * as Lang from "@/i18n/lang";
import Label, { LabelType } from "@/model/Labels/Label";
import DatabaseElement from "../Elements/DatabaseElement";
import * as Firebase from "@/firebase/Firebase";

export default class DatabaseLabel extends Label {
  constructor(
    ref: DocumentReference,
    active: boolean | null = false,
    cumulate_expiration_duration: boolean | null = false,
    defrosting_duration: number | null = 0,
    element: DocumentReference | null = null,
    expiration_duration: number | null = 0,
    expiration_text: string | null = null,
    name: string | null = "",
    type: LabelType | null = null,
    index: number | null = null,
    show_expiration_hour: boolean | null = true,
    show_expiration_weekday: boolean | null = true
  ) {
    super(
      ref,
      active,
      cumulate_expiration_duration,
      defrosting_duration,
      element,
      expiration_duration,
      expiration_text,
      name,
      type,
      index,
      show_expiration_hour,
      show_expiration_weekday
    );
  }

  public static fromFirestore(snapshot: DocumentSnapshot): DatabaseLabel {
    const data = snapshot.data()!;

    let result = new DatabaseLabel(
      snapshot.ref,
      data.active,
      data.cumulate_expiration_duration,
      data.defrosting_duration,
      data.element,
      data.expiration_duration,
      data.expiration_text,
      data.name,
      data.type,
      data.index == undefined ? null : data.index,
      data.show_expiration_hour == undefined ? true : data.show_expiration_hour,
      data.show_expiration_weekday == undefined
        ? true
        : data.show_expiration_weekday
    );

    return result;
  }

  public toFirestore() {
    return {
      active: this.active,
      cumulate_expiration_duration: this.cumulate_expiration_duration,
      defrosting_duration: this.defrosting_duration,
      element: this.element,
      expiration_duration: this.expiration_duration,
      expiration_text: this.expiration_text,
      name: this.name,
      type: this.type,
      index: this.index,
      show_expiration_hour: this.show_expiration_hour,
      show_expiration_weekday: this.show_expiration_weekday,
    };
  }

  public duplicate(targetElement: DatabaseElement) {
    var path = targetElement.ref.parent.parent.path + "/labels";
    var ref = doc(collection(Firebase.firestore, path));

    console.log(ref);
    return new DatabaseLabel(
      ref,
      this.active,
      this.cumulate_expiration_duration,
      this.defrosting_duration,
      this.element,
      this.expiration_duration,
      this.expiration_text,
      this.name,
      this.type,
      this.index,
      this.show_expiration_hour,
      this.show_expiration_weekday
    );
  }
}
