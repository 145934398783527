import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "flex col grow",
  style: {"width":"450px","gap":"30px"}
}
const _hoisted_2 = { class: "flex justify-between center" }
const _hoisted_3 = { class: "text-delete" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_SeFadeButton = _resolveComponent("SeFadeButton")!
  const _component_SeModal = _resolveComponent("SeModal")!

  return (_openBlock(), _createBlock(_component_SeModal, {
    title: _ctx.Lang.getI18N('delete-dialog'),
    ref: "confirmDeleteModal"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("delete-confirm")), 1),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SeButton, {
            onPress: () => _ctx.closeModal(_ctx.$refs.confirmDeleteModal),
            style: {"width":"100px"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("cancel")), 1)
            ]),
            _: 1
          }, 8, ["onPress"]),
          _createVNode(_component_SeFadeButton, {
            style: {"width":"100px"},
            class: "modal-valid",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleAction(_ctx.$refs.confirmDeleteModal)))
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.Lang.getI18N("delete")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}