import { defineStore } from "pinia";
import * as DistributorManager from "@/firebase/DistributorManager";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { collection, DocumentData, Query, query } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import {
  where,
  doc,
  getDocs,
  getDoc,
  addDoc,
  documentId,
  onSnapshot,
  updateDoc,
  DocumentReference,
  limit,
} from "firebase/firestore";
import Distributor from "@/model/Distributor";
import { getState } from "@/pinia/AppState";
import { Role } from "@/model/User";
import { getDatabaseManager } from "./DatabaseManager";
import { getStoreManager } from "./StoreManager";

interface DistributorManager {
  listener: SnapshotListener<Distributor> | null;
}

export const getDistributorManager = defineStore("DistributorManager", {
  state: (): DistributorManager => {
    return {
      listener: null,
    };
  },
  actions: {
    async initialize() {
      if (this.listener != null) {
        return;
      }

      let user = getState().userData!;

      let q: Query<DocumentData> | null = null;

      if (user.role == Role.Admin) {
        q = query(collection(Firebase.firestore, "distributors"));
      } else if (user.role == Role.Distributor) {
        q = query(
          collection(Firebase.firestore, "distributors"),
          where("__name__", "==", user.distributor.id)
        );
      } else if (user.role == Role.Manager) {
        await getDatabaseManager().initialize();

        let distributorsIds: string[] = [];

        for (let database of getDatabaseManager().getDatabases()) {
          if (database.distributor != null) {
            if (!distributorsIds.includes(database.distributor.id))
              distributorsIds.push(database.distributor.id!);
          }
        }

        console.log(distributorsIds);

        q = query(
          collection(Firebase.firestore, "distributors"),
          where(documentId(), "in", distributorsIds)
        );
      } else if (user.role == Role.Customer) {
        q = query(
          collection(Firebase.firestore, "distributors"),
          where(documentId(), "in", user.store_distributors)
        );
      }

      this.listener = new SnapshotListener(Distributor.fromFirestore, q);

      await this.listener?.ensureInit();
    },

    getDistributorFromCache(ref: DocumentReference): Distributor {
      let distributors = this.listener?.items!;

      let result = distributors.find((x) => x.ref?.id === ref.id);

      if (result) {
        return result;
      } else {
        return null;
      }
    },
    async getDistributorByReference(
      ref: DocumentReference
    ): Promise<Distributor> {
      if (this.listener) {
        var result = this.getDistributorFromCache(ref);

        if (result != null) {
          return result;
        }
      }

      let doc = await getDoc(ref);
      return Distributor.fromFirestore(doc);
    },

    getDistributors(): Distributor[] {
      return this.listener?.items!;
    },

    async getDistributorFromOwner(
      userRef: DocumentReference
    ): Promise<Distributor | null> {
      const q = query(
        collection(Firebase.firestore, "distributors"),
        where("owners", "array-contains", userRef),
        limit(1)
      );
      let snapshot = await getDocs(q);

      if (snapshot.empty) {
        return null;
      } else {
        return Distributor.fromFirestore(snapshot.docs[0]);
      }
    },
  },
});
