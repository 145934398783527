
import { defineComponent, nextTick } from "vue";
import * as Lang from "@/i18n/lang";
import { ProfilingChartType } from "./ProfilingChartType";
import { getHomeState } from "@/pinia/home/HomeState";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import TaskGenerationEvent from "./TaskGenerationEvent";
import * as DateUtils from "@/utils/DateUtils";
import * as Modals from "@/utils/Modals";
import { getState as getAppState } from "@/pinia/AppState";
import Store from "@/model/Store";
import { getStoreManager } from "@/firebase/StoreManager";

const config = require("@/config.json");
const firebaseOptions = require("@/firebase_options.json");

export default defineComponent({
  async setup() {
    await getStoreManager().initialize();

    return {
      stores: getStoreManager().getStores(true),
    };
  },
  data() {
    var state = getHomeState();
    var appState = getAppState();

    return {
      config: config,
      intervalId: {} as any,
      output: null as any,
      projectId: firebaseOptions.projectId,
      taskGenerationIssues: [] as TaskGenerationEvent[],
      state: state,
      ProfilingChartType: ProfilingChartType,
      Lang: Lang,
      appState: appState,
    };
  },
  methods: {
    async search(search: string) {
      var refs = <any>this.$refs;


      var store: Store = refs.storeSelect.getValue();

      if (store == null) {
        Modals.open("Veuillez selectionner un établissement.");
        return;
      }


      var start = refs.calendar.getStart();

      var end = refs.calendar.getEnd();

      this.taskGenerationIssues =
        await SecureatServerApi.searchTaskGenerationEvent(
          start,
          end,
          store.ref!.id!
        );

    },
    getEventName(typeId: number) {
      if (typeId == 2) {
        return "Warning";
      } else if (typeId == 1) {
        return "Error";
      } else if (typeId == 0) {
        return "Program starting";
      } else if (typeId == 3) {
        return "Store generation started";
      } else if (typeId == 4) {
        return "Task generated";
      }
    },
    getProfilingChartTypeName(value: ProfilingChartType) {
      return Lang.getI18N(value.toString());
    },
    onTabChange(index: number) { },

    getDateString(taskGenerationEvent: TaskGenerationEvent) {
      return DateUtils.formatDateWithTime(
        new Date(taskGenerationEvent.timestamp)
      );
    },
    async updateOutput() {
      var refs = <any>this.$refs;

      var newOutput = await SecureatServerApi.getOuput();

      if (this.output == null) {
        this.output = newOutput;
        return;
      }

      let diff =
        newOutput.StandardOutput.length != this.output.StandardOutput.length;

      this.output = newOutput;

      if (refs.textarea == undefined) {
        return;
      }
    },
  },

  async mounted() {
    if (getAppState().connectedToApi) {
      this.intervalId = setInterval(this.updateOutput, 1000);
    }
  },
  unmounted() {
    clearInterval(this.intervalId);
  },
});
