import { defineStore } from "pinia";
import User, { Role } from "@/model/User";
import Store from "@/model/Store";
import Database from "@/model/Database";
import Probe from "@/model/Probe";
import Room from "@/model/Room";
import * as StoreManager from "@/firebase/StoreManager";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getUserManager } from "@/firebase/UserManager";
import { getState as getAppState } from "@/pinia/AppState";
import { getStoreManager } from "@/firebase/StoreManager";
import { getProbesManager } from "@/firebase/ProbeManager";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import { KooveaSticker } from "@/model/Koovea/KooveaSticker";

interface StoreState {
  selectedStore: Store | null;
  initialStore: Store | null;
  currentDatabase: Database | null;
  role: Role | null;
  databases: Database[] | null;
  owners: User[] | null;
  selectedDatabase: Database | null;

}

export const getStoreState = defineStore("storeState", {
  state: (): StoreState => {
    return {
      initialStore: null,
      selectedStore: null,
      currentDatabase: null,
      role: null,
      databases: null,
      owners: null,
      selectedDatabase: null,
    };
  },
  actions: {
    set(store: Store) {
      this.probes = [];
      this.initialStore = store;
      this.selectedStore = Store.clone(store);
      this.selectedHub = null;
    },
    async setup() {




      let databaseManager = getDatabaseManager();

      await databaseManager.initialize();
      this.databases = databaseManager
        .getDatabases()
        .filter(
          (x) =>
            x.distributor != null &&
            x.distributor.id == this.selectedStore.distributor.id
        );

      this.role = getAppState().userData!.role as Role;

      this.owners = await getUserManager().getStoreOwners(this.selectedStore!);

      if (this.selectedStore!.database != null) {
        this.currentDatabase =
          await getDatabaseManager().getDatabaseByReference(
            this.selectedStore!.database
          );
      } else {
        this.currentDatabase = null;
      }
    },
  },
});
