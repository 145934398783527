import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex g12" }
const _hoisted_2 = { style: {"width":"80vw","height":"70vh","overflow-y":"scroll"} }
const _hoisted_3 = {
  ref: "grid",
  class: "grid single",
  style: {"max-height":"40vh","overflow-y":"scroll"}
}
const _hoisted_4 = { class: "text-muted center" }
const _hoisted_5 = {
  style: {"display":"none","width":"300px"},
  ref: "errorLabel",
  class: "text-red center"
}
const _hoisted_6 = {
  key: 0,
  class: "flex align-center"
}
const _hoisted_7 = { class: "general-container" }
const _hoisted_8 = { class: "settings-header" }
const _hoisted_9 = { class: "flex align-center" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = { class: "settings-container wrap" }
const _hoisted_12 = { class: "flex col g24 grow" }
const _hoisted_13 = { class: "settings-card" }
const _hoisted_14 = {
  key: 0,
  class: "settings-card"
}
const _hoisted_15 = { class: "settings-card" }
const _hoisted_16 = { class: "settings-card" }
const _hoisted_17 = { class: "flex col g24 grow" }
const _hoisted_18 = {
  key: 0,
  class: "settings-card"
}
const _hoisted_19 = { style: {"background-color":"#f3fafa"} }
const _hoisted_20 = { style: {"background-color":"#f3fafa"} }
const _hoisted_21 = { class: "general-container" }
const _hoisted_22 = { class: "general-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeCheckboxGroup = _resolveComponent("SeCheckboxGroup")!
  const _component_SeModal = _resolveComponent("SeModal")!
  const _component_DatabaseShop = _resolveComponent("DatabaseShop")!
  const _component_SeFadeButton = _resolveComponent("SeFadeButton")!
  const _component_WideCard = _resolveComponent("WideCard")!
  const _component_SeInput = _resolveComponent("SeInput")!
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_SeForm = _resolveComponent("SeForm")!
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_SeIllustration = _resolveComponent("SeIllustration")!
  const _component_ReadonlyField = _resolveComponent("ReadonlyField")!
  const _component_SeDropdownItem = _resolveComponent("SeDropdownItem")!
  const _component_SeDropdown = _resolveComponent("SeDropdown")!
  const _component_ToggleSwitch = _resolveComponent("ToggleSwitch")!
  const _component_ElementList = _resolveComponent("ElementList")!
  const _component_TaskConfigurationList = _resolveComponent("TaskConfigurationList")!
  const _component_TagsEditor = _resolveComponent("TagsEditor")!
  const _component_EmployeeRoleEditor = _resolveComponent("EmployeeRoleEditor")!
  const _component_SeTab = _resolveComponent("SeTab")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SeModal, {
      ref: "moduleModal",
      title: "Modules"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, _toDisplayString(_ctx.Lang.getI18N("features-select")) + ":", 1),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_SeCheckboxGroup, {
            title: "Modules",
            class: "p12",
            ref: "modulesSelect",
            labelFunction: _ctx.getLabelFromI18N,
            items: _ctx.getModuleEnums(),
            hidden: [_ctx.ModuleEnum.Action]
          }, null, 8, ["labelFunction", "items", "hidden"]),
          _createVNode(_component_SeCheckboxGroup, {
            title: "Extensions",
            class: "p12",
            ref: "extensionsSelect",
            labelFunction: _ctx.getLabelFromI18N,
            items: _ctx.getExtensionEnums()
          }, null, 8, ["labelFunction", "items"])
        ])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_SeModal, {
      ref: "shopModal",
      title: "Shop"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_DatabaseShop)
        ])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_SeModal, {
      title: _ctx.Lang.getI18N('managers'),
      ref: "modal"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.managers, (manager) => {
            return (_openBlock(), _createBlock(_component_WideCard, {
              key: manager.ref?.id,
              icon: "/ico/account.svg",
              title: manager.name + ' ' + manager.surname
            }, {
              subtitle: _withCtx(() => [
                _createTextVNode(_toDisplayString(manager.email), 1)
              ]),
              footer: _withCtx(() => [
                _createVNode(_component_SeFadeButton, {
                  onClick: ($event: any) => (_ctx.removeManager(manager)),
                  icon: "/ico/trash.svg"
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1032, ["title"]))
          }), 128))
        ], 512),
        _createVNode(_component_SeForm, {
          ref: "managerForm",
          class: "flex col"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.Lang.getI18N("enter-the-email-of-the-user")) + " : ", 1),
            _createVNode(_component_SeInput, {
              required: "",
              ref: "ownerName"
            }, null, 512),
            _createVNode(_component_SeButton, {
              ref: "addManagerBtn",
              onPress: _ctx.addManager,
              class: "modal-valid center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("add")), 1)
              ]),
              _: 1
            }, 8, ["onPress"]),
            _createElementVNode("span", _hoisted_5, "Unknown", 512)
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", {
      onScroll: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onScroll && _ctx.onScroll(...args))),
      ref: "mainLayout",
      class: "main-layout"
    }, [
      _createVNode(_component_NavHeader, {
        backButton: "true",
        title: _ctx.state.database!.name
      }, null, 8, ["title"]),
      _createVNode(_component_SeTab, {
        onTabChange: _ctx.onTabChange,
        ref: "tab",
        tabs: [
        _ctx.Lang.getI18N('general'),
        _ctx.Lang.getI18N('elements'),
        _ctx.Lang.getI18N('tasks'),
        'Tags',
        _ctx.Lang.getI18N('employee-roles'),
      ],
        icons: [
        'ico/databases_gray.svg',
        '/ico/element-gray.svg',
        '/ico/tasks.svg',
        'ico/store_tags.svg',
        'ico/employee_role.svg',
      ],
        style: {"margin":"0px 24px 0px 24px"}
      }, {
        sticky: _withCtx(() => [
          (
            _ctx.state.tabIndex != 0 && _ctx.state.tabIndex != 3 && _ctx.state.tabIndex != 4
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_ctx.state.tabIndex == 1 || _ctx.state.tabIndex == 2)
                  ? (_openBlock(), _createBlock(_component_SearchBar, {
                      key: 0,
                      searchChange: 
              _ctx.state.tabIndex == 1 ? _ctx.onElementSearchChange : _ctx.onTaskSearchChange
            ,
                      noToggle: true,
                      btnRedirect: "newElement",
                      newCallback: 
              _ctx.state.tabIndex == 1
                ? _ctx.openElementCreationPage
                : _ctx.openTaskCreationPage
            ,
                      targetGridId: _ctx.state.tabIndex == 1 ? 'elementGrid' : 'taskGrid',
                      btnText: 
              _ctx.state.tabIndex == 1
                ? _ctx.Lang.getI18N('new-element')
                : _ctx.Lang.getI18N('new-task')
            ,
                      ref: "search",
                      modelValue: _ctx.state.displayUnactives,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.displayUnactives) = $event)),
                      class: "grow"
                    }, null, 8, ["searchChange", "newCallback", "targetGridId", "btnText", "modelValue"]))
                  : _createCommentVNode("", true),
                (
              (_ctx.state.tabIndex == 1 || _ctx.state.tabIndex == 2) &&
              _ctx.user.role != _ctx.Role.Customer
            )
                  ? (_openBlock(), _createBlock(_component_SeButton, {
                      key: 1,
                      class: "no-mobile",
                      icon: "/ico/expert.svg",
                      onPress: () => _ctx.openBulk(_ctx.state.tabIndex)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("expert-mode")), 1)
                      ]),
                      _: 1
                    }, 8, ["onPress"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        "0": _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_SeIllustration, {
                  src: _ctx.state.database!.illustration_url == null
                ? '/ico/database.svg'
                : _ctx.state.database!.illustration_url.get()
                ,
                  onFilePick: _ctx.onIllustrationChange
                }, null, 8, ["src", "onFilePick"]),
                _createVNode(_component_ReadonlyField, {
                  locked: _ctx.state.database.hidden
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SeInput, {
                      style: {"width":"80vw","max-width":"350px"},
                      modelValue: _ctx.state.database!.name,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.database!.name) = $event)),
                      background: "#F3FAFA"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["locked"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_SeButton, {
                  ref: "modifyBtn",
                  onPress: _ctx.updateDatabase,
                  icon: "/ico/save.svg",
                  tooltip: _ctx.Lang.getI18N('save')
                }, null, 8, ["onPress", "tooltip"])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _withDirectives(_createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.Lang.getI18N("features")) + ":", 1),
                  _createVNode(_component_SeButton, {
                    icon: "/ico/edit.svg",
                    onPress: _ctx.openModulesModal
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("modify")), 1)
                    ]),
                    _: 1
                  }, 8, ["onPress"])
                ], 512), [
                  [_vShow, !_ctx.state.database.hidden]
                ]),
                (!_ctx.state.database.hidden)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.Lang.getI18N("managers")) + ":", 1),
                      _createVNode(_component_SeButton, {
                        onPress: _ctx.openManagersModal,
                        icon: "/ico/edit.svg"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("modify")), 1)
                        ]),
                        _: 1
                      }, 8, ["onPress"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.Lang.getI18N("distributor")) + ":", 1),
                  _createVNode(_component_ReadonlyField, {
                    locked: _ctx.state.database!.distributor != null
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SeDropdown, {
                        modelValue: _ctx.state.selectedDistributor,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.selectedDistributor) = $event)),
                        titleFunc: (distributor) => { return distributor.name }
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.distributors, (distributor) => {
                            return (_openBlock(), _createBlock(_component_SeDropdownItem, { value: distributor }, null, 8, ["value"]))
                          }), 256))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "titleFunc"])
                    ]),
                    _: 1
                  }, 8, ["locked"])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_SeButton, {
                    class: "grow",
                    onPress: _ctx.openAllergensView,
                    icon: "/ico/auditing.svg"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("button-allergens-open")), 1)
                    ]),
                    _: 1
                  }, 8, ["onPress"])
                ])
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_17, [
                _createVNode(_component_ReadonlyField, {
                  locked: _ctx.user!.role != _ctx.Role.Distributor && _ctx.user!.role != _ctx.Role.Admin,
                  class: "settings-card"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.Lang.getI18N("database.history-manager")), 1),
                    _createVNode(_component_ToggleSwitch, {
                      modelValue: _ctx.state.database!.store_access,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.database!.store_access) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["locked"]),
                (_ctx.user!.role == _ctx.Role.Distributor || _ctx.user!.role == _ctx.Role.Admin)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("shop")), 1),
                      _createVNode(_component_SeButton, {
                        onPress: _ctx.openShop,
                        icon: "/ico/edit.svg"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("modify")), 1)
                        ]),
                        _: 1
                      }, 8, ["onPress"])
                    ]))
                  : _createCommentVNode("", true)
              ], 512), [
                [_vShow, !_ctx.state.database.hidden]
              ])
            ])
          ])
        ]),
        "1": _withCtx(() => [
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_ElementList, {
              unactives: _ctx.state.displayUnactives,
              storeDatabase: false,
              elements: _ctx.state.elements,
              database: _ctx.state.database!
            }, null, 8, ["unactives", "elements", "database"])
          ])
        ]),
        "2": _withCtx(() => [
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_TaskConfigurationList, {
              isStoreTask: false,
              tasks: _ctx.state.taskConfigurations,
              database: _ctx.state.database!,
              unactives: _ctx.state.displayUnactives
            }, null, 8, ["tasks", "database", "unactives"])
          ])
        ]),
        "3": _withCtx(() => [
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_TagsEditor)
          ])
        ]),
        "4": _withCtx(() => [
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_EmployeeRoleEditor)
          ])
        ]),
        _: 1
      }, 8, ["onTabChange", "tabs"])
    ], 544)
  ], 64))
}