import Task from "@/model/Task";
import TaskResult from "@/model/TaskResults/TaskResult";
import * as TaskManager from "@/firebase/TaskManager";
import { TaskStatusEnum } from "@/components/tasks/TaskStatusEnum";

export default class TaskWithResult {
  getStatus(): TaskStatusEnum {
    return TaskManager.getTaskStatus(this.task);
  }
  task: Task;
  results: TaskResult[];

  constructor(task: Task, results: TaskResult[]) {
    this.task = task;
    this.results = results;
  }
}
