
import * as Lang from "@/i18n/lang";
import * as Snackbars from "@/utils/Snackbars";
import SearchBar from "@/components/global/SearchBar.vue";
import { defineComponent } from "vue";
import { nextTick } from "vue";
import { getDatabaseState } from "@/pinia/databases/DatabaseState";
import { collection, doc, DocumentReference } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import SeFadeButton from "@/components/global/SeFadeButton.vue";
import Tag from "@/model/Tag";
import SeEditableText from "@/components/global/SeEditableText.vue";
import Store from "@/model/Store";
import { getStoreManager } from "@/firebase/StoreManager";
import SeDropdown from "@/components/global/dropdown/SeDropdown.vue";
import SeCheckboxGroup from "@/components/global/SeCheckboxGroup.vue";
import * as Modals from "@/utils/Modals";

export default defineComponent({
  components: {},

  methods: {
    async removeTag(tag: Tag) {
      if (
        await Modals.openYesNo(
          "Etes vous sur de vouloir supprimer ce tag?",
          "Supprimer",
          "Oui",
          "Non"
        )
      ) {
        tag.active = false;
      }
    },
    onSearchChange(value: string) {
      if (value.trim() == "") {
        this.displayedStoreWithoutTags = this.storesWithoutTag;
        return;
      }
      this.displayedStoreWithoutTags = this.storesWithoutTag.filter((store) =>
        store.name.toLowerCase().includes(value.toLowerCase())
      );
    },
    onTagChange(tag: Tag) {
      this.selectTag(tag);
      this.update();
    },
    addStores() {
      let refs = <any>this.$refs;

      refs.storesWithoutTag.getSelectedItems().forEach((store: Store) => {
        this.addStoreToTag(store);
      });

      this.update();

      nextTick(() => {
        refs.storesWithoutTag.unselectAll();
      });
    },
    addStoreToTag(store: Store) {
      if (this.storesWithTag.some((x) => x.ref.id == store.ref.id)) {
        console.log("Store already added");
        return;
      }
      store.tags.push(this.selectedTag.ref);

      if (this.storesToUpdate.indexOf(store) == -1) {
        this.storesToUpdate.push(store);
      }
    },
    selectTag(tag: Tag) {
      this.selectedTag = tag;
      var refs = <any>this.$refs;
      refs.tagDropdown.setValue(tag);
      this.update();
    },
    async addTag() {
      this.state.tags.push(
        new Tag(
          doc(
            collection(
              Firebase.firestore,
              this.state.database!.ref.path,
              "store_tags"
            )
          ),
          "Nouveau tag",
          true
        )
      );
      var refs = <any>this.$refs;

      refs.tagDropdown.$forceUpdate();
      this.update();
      this.$forceUpdate();
    },
    removeStores() {
      let refs = <any>this.$refs;

      refs.storesWithTag.getSelectedItems().forEach((store: Store) => {
        this.removeStoreFromTag(store);
      });

      this.update();

      nextTick(() => {
        refs.storesWithTag.unselectAll();
      });
    },
    removeStoreFromTag(store: Store) {
      let removed: DocumentReference | null = null;
      for (let tag of store.tags) {
        if (tag.id == this.selectedTag.ref.id) {
          removed = tag;
        }
      }
      store.tags.splice(store.tags.indexOf(removed), 1);

      if (this.storesToUpdate.indexOf(store) == -1) {
        this.storesToUpdate.push(store);
      }

      this.update();
    },
    async saveTags() {
      var refs = <any>this.$refs;



      for (let tag of this.state.tags) {
        if (this.state.tags.some((x) => x != tag && x.name == tag.name)) {
          Snackbars.display(
            "Deux tags ne peuvent pas avoir le même nom",
            1000,
            "error"
          );
          return;
        }
      }


      // pas deux tag avec deux fois le même tempss

      for (let tag of this.state.tags) {
        await tag.set();
      }

      for (let storesToUpdate of this.storesToUpdate) {
        await storesToUpdate.set();
      }

      Snackbars.display("Tags sauvegardés");
    },
    getAllStores() {
      return getStoreManager()
        .getStores(true)
        .filter((x) => x.database.id == this.state.database!.ref.id);
    },
    update() {
      var stores = this.getAllStores();

      this.storesWithoutTag = stores.filter(
        (x) => !x.tags.some((x) => x.id == this.selectedTag.ref.id)
      );

      this.displayedStoreWithoutTags = this.storesWithoutTag.map((x) => x);

      this.storesWithTag = stores.filter((x) =>
        x.tags.some((x) => x.id == this.selectedTag.ref.id)
      );

      for (let store of this.displayedStoreWithoutTags) {
        if (!this.storesWithoutTag.some((x) => x.ref.id == store.ref.id)) {
          this.displayedStoreWithoutTags.splice(
            this.displayedStoreWithoutTags.indexOf(store),
            1
          );
        }
      }
    },
  },

  data() {
    return {
      storesWithoutTag: [] as Store[],
      displayedStoreWithoutTags: [] as Store[],
      storesWithTag: [] as Store[],
      selectedTag: null as Tag | null,
      storesToUpdate: [] as Store[],
      Lang: Lang,
    };
  },

  async mounted() {
    this.selectTag(this.state.tags[0]);
    this.update();
  },

  async setup() {
    let state = getDatabaseState();

    return {
      state: state,
    };
  },
});
