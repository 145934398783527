import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex grow" }
const _hoisted_2 = { class: "bold" }
const _hoisted_3 = { class: "text-muted f12" }
const _hoisted_4 = { class: "text-muted f12" }
const _hoisted_5 = { class: "text-muted f12" }
const _hoisted_6 = { class: "bold" }
const _hoisted_7 = { class: "text-muted f12" }
const _hoisted_8 = { class: "text-muted f12" }
const _hoisted_9 = { class: "text-muted f12" }
const _hoisted_10 = { class: "bold" }
const _hoisted_11 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_12 = {
  key: 1,
  class: "text-muted f12"
}
const _hoisted_13 = { class: "text-muted f12" }
const _hoisted_14 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_15 = {
  key: 1,
  class: "text-muted f12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tab = _resolveComponent("Tab")!
  const _component_Column = _resolveComponent("Column")!
  const _component_SeImage = _resolveComponent("SeImage")!
  const _component_Value = _resolveComponent("Value")!
  const _component_Dot = _resolveComponent("Dot")!
  const _component_Row = _resolveComponent("Row")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    title: _ctx.Lang.getI18N('equipment-temperature')
  }, {
    header: _withCtx(() => [
      _createVNode(_component_Tab, {
        onSwitch: _ctx.onFilterChange,
        labels: [_ctx.Lang.getI18N('fridges'), _ctx.Lang.getI18N('Delivery'), _ctx.Lang.getI18N('Oils')]
      }, null, 8, ["onSwitch", "labels"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.filter == _ctx.EquipementTemperaturesFilter.Fridges)
          ? (_openBlock(), _createBlock(_component_Table, { key: 0 }, {
              columns: _withCtx(() => [
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("name")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("status")), 1)
                  ]),
                  _: 1
                })
              ]),
              rows: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.homeState.fridgeElements, (element) => {
                  return (_openBlock(), _createBlock(_component_Row, {
                    key: element[0].ref.id
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Value, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_SeImage, {
                            maxWidth: 35,
                            maxHeight: 35,
                            src: element[0].getIcon()
                          }, null, 8, ["src"]),
                          _createElementVNode("span", _hoisted_2, _toDisplayString(element[0].getName()), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_Value, null, {
                        default: _withCtx(() => [
                          (element[1] == _ctx.ElementTemperatureStateEnum.InRange)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.Lang.getI18N("in-range")), 1),
                                _createVNode(_component_Dot, { variant: "green" })
                              ], 64))
                            : _createCommentVNode("", true),
                          (element[1] == _ctx.ElementTemperatureStateEnum.Mixed)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.Lang.getI18N("mixed")), 1),
                                _createVNode(_component_Dot, { variant: "orange" })
                              ], 64))
                            : _createCommentVNode("", true),
                          (element[1] == _ctx.ElementTemperatureStateEnum.OutOfRange)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.Lang.getI18N("out-of-range")), 1),
                                _createVNode(_component_Dot, { variant: "red" })
                              ], 64))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.filter == _ctx.EquipementTemperaturesFilter.Deliveries)
          ? (_openBlock(), _createBlock(_component_Table, { key: 1 }, {
              columns: _withCtx(() => [
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("name")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("status")), 1)
                  ]),
                  _: 1
                })
              ]),
              rows: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.homeState.deliveryElements, (element) => {
                  return (_openBlock(), _createBlock(_component_Row, {
                    key: element[0].ref.id
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Value, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_SeImage, {
                            maxWidth: 35,
                            maxHeight: 35,
                            src: element[0].getIcon()
                          }, null, 8, ["src"]),
                          _createElementVNode("span", _hoisted_6, _toDisplayString(element[0].getName()), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_Value, null, {
                        default: _withCtx(() => [
                          (element[1] == _ctx.ElementTemperatureStateEnum.InRange)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.Lang.getI18N("in-range")), 1),
                                _createVNode(_component_Dot, { variant: "green" })
                              ], 64))
                            : _createCommentVNode("", true),
                          (element[1] == _ctx.ElementTemperatureStateEnum.Mixed)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.Lang.getI18N("mixed")), 1),
                                _createVNode(_component_Dot, { variant: "orange" })
                              ], 64))
                            : _createCommentVNode("", true),
                          (element[1] == _ctx.ElementTemperatureStateEnum.OutOfRange)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.Lang.getI18N("out-of-range")), 1),
                                _createVNode(_component_Dot, { variant: "red" })
                              ], 64))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.filter == _ctx.EquipementTemperaturesFilter.Oils)
          ? (_openBlock(), _createBlock(_component_Table, { key: 2 }, {
              columns: _withCtx(() => [
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("name")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("temperature")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("last-change")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("polarity")), 1)
                  ]),
                  _: 1
                })
              ]),
              rows: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.homeState.oilElements, (element) => {
                  return (_openBlock(), _createBlock(_component_Row, {
                    key: element[0].ref.id
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Value, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_SeImage, {
                            maxWidth: 35,
                            maxHeight: 35,
                            src: element[0].getIcon()
                          }, null, 8, ["src"]),
                          _createElementVNode("span", _hoisted_10, _toDisplayString(element[0].getName()), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_Value, null, {
                        default: _withCtx(() => [
                          (element[1].temperature)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(element[1].temperature) + "°C", 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.Lang.getI18N("no-data")), 1))
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_Value, null, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_13, _toDisplayString(element[1].timestamp), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_Value, null, {
                        default: _withCtx(() => [
                          (element[1].polarity)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(element[1].polarity) + "°C", 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.Lang.getI18N("no-data")), 1))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}