import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "flex col p12 grow",
  style: {"max-height":"300px","overflow":"scroll"}
}
const _hoisted_2 = { class: "f14 bold" }
const _hoisted_3 = { class: "flex grow justify-between" }
const _hoisted_4 = { class: "flex col g0" }
const _hoisted_5 = { class: "text-muted" }
const _hoisted_6 = { class: "f14" }
const _hoisted_7 = { class: "flex col g0" }
const _hoisted_8 = { class: "text-muted" }
const _hoisted_9 = { class: "f14" }
const _hoisted_10 = { class: "flex col g0 align-start" }
const _hoisted_11 = { class: "text-muted" }
const _hoisted_12 = { class: "flex align-center justify-center g3" }
const _hoisted_13 = { class: "text-muted f10" }
const _hoisted_14 = { class: "f14 bold" }
const _hoisted_15 = { class: "flex grow justify-between" }
const _hoisted_16 = { class: "flex col g0" }
const _hoisted_17 = { class: "text-muted" }
const _hoisted_18 = { class: "f14" }
const _hoisted_19 = { class: "flex col g0 align-start" }
const _hoisted_20 = { class: "text-muted" }
const _hoisted_21 = { class: "flex align-center justify-center g3" }
const _hoisted_22 = { class: "text-muted f10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_SeProgressBar = _resolveComponent("SeProgressBar")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    title: _ctx.Lang.getI18N('communication-training')
  }, {
    header: _withCtx(() => []),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.homeState.communications.filter(
          (x) => x.active
        ), (communication) => {
          return (_openBlock(), _createBlock(_component_Card, {
            onClick: ($event: any) => (_ctx.openCommunicationStats(communication)),
            key: communication.ref.id
          }, {
            header: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(communication.name), 1),
              _createVNode(_component_Badge, { color: "#327ad1" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("communication")), 1)
                ]),
                _: 1
              })
            ]),
            body: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.Lang.getI18N("start-date")), 1),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.DateUtils.formatDate(communication.start)), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.Lang.getI18N("end-date")), 1),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.DateUtils.formatDate(communication.end)), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.Lang.getI18N("who-saw-it")), 1),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_SeProgressBar, {
                      value: _ctx.getCommunicationPercentageView(communication)
                    }, null, 8, ["value"]),
                    _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.getCommunicationPercentageView(communication)) + "%", 1)
                  ])
                ])
              ])
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.homeState.trainings.filter((x) => x.active), (training) => {
          return (_openBlock(), _createBlock(_component_Card, {
            onClick: ($event: any) => (_ctx.openTrainingStats(training)),
            key: training.ref.id
          }, {
            header: _withCtx(() => [
              _createElementVNode("span", _hoisted_14, _toDisplayString(training.name), 1),
              _createVNode(_component_Badge, { color: "#d404b5" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("training")), 1)
                ]),
                _: 1
              })
            ]),
            body: _withCtx(() => [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.Lang.getI18N("type")), 1),
                  _createElementVNode("span", _hoisted_18, _toDisplayString(training.type), 1)
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.Lang.getI18N("who-saw-it")), 1),
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_SeProgressBar, {
                      value: _ctx.getTrainingPercentageView(training)
                    }, null, 8, ["value"]),
                    _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.getTrainingPercentageView(training)) + "%", 1)
                  ])
                ])
              ])
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}