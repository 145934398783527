import {
  DocumentSnapshot,
  DocumentReference,
  Timestamp,
} from "firebase/firestore";
import SecureatSnapshot from "./SecureatSnapshot";

export default class Tag extends SecureatSnapshot {
  name: string = "";

  active: boolean = false;

  constructor(ref: DocumentReference, name: string, active: boolean) {
    super(ref);
    this.name = name;
    this.active = active;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): Tag {
    const data = snapshot.data()!;

    let tag = new Tag(
      snapshot.ref,
      data.name,
      data.active != undefined ? data.active : true
    );
    return tag;
  }

  public toFirestore() {
    return {
      name: this.name,
      active: this.active,
    };
  }
}
