
import { defineComponent } from "@vue/runtime-core";
import * as TaskManager from "@/firebase/TaskManager";
import ActionResultRow from "@/components/tasks/table/ActionResultRow.vue";
import Task from "@/model/Task";
import Store from "@/model/Store";
import TaskStatus from "../TaskStatus.vue";
import OilResultRow from "./OilResultRow.vue";
import * as Lang from "@/i18n/lang";
import TaskWithResult from "@/pinia/tasks/TaskWithResult";
import * as DateUtils from "@/utils/DateUtils";

export default defineComponent({
  props: {
    taskWrapper: {
      type: TaskWithResult,
      required: true,
    },
    store: {
      type: Store,
    },
  },

  methods: {},
  async setup(props) {},
  data() {
    return {
      TaskManager: TaskManager,
      Lang: Lang,
      DateUtils: DateUtils,
    };
  },
  components: {
    ActionResultRow,
    TaskStatus,
    OilResultRow,
  },
});
